import axios from "axios";
import { BASE_API } from "../../utils/baseApi";

export const getShippingInfo = () => async (dispatch: any) => {
  try {
    dispatch({ type: "shipping/shippingLoading" });

    const { data } = await axios.get(`${BASE_API}/api/v1/shipping`, {
      withCredentials: true,
    });
    // console.log("ship", data);
    dispatch({ type: "shipping/shippingInfo", payload: data });
    dispatch({ type: "shipping/shippingLoading" });
  } catch (error: any) {
    dispatch({ type: "shipping/shippingLoading" });
    dispatch({
      type: "shipping/shippingError",
      payload: error.message,
    });
  }
};

export const saveShippingInfo =
  ({ address = "", number = "", landmark = "" }) =>
  async (dispatch: any) => {
    try {
      dispatch({ type: "shipping/shippingLoading" });

      const { data } = await axios.put(
        `${BASE_API}/api/v1/shipping`,
        {
          headers: {},
          data: {
            address,
            number,
            landmark,
          },
        },
        {
          withCredentials: true,
        }
      );
      dispatch({ type: "shipping/shippingInfo", payload: data });
      dispatch({ type: "shipping/shippingLoading" });
    } catch (error) {
      dispatch({ type: "shipping/shippingLoading" });
      dispatch({ type: "shipping/shippingError", payload: error });
    }
  };
