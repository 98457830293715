import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector } from "../features/hooks/hooks";

const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-decoration: none;
  list-style-type: none;
  margin-top: 40px;
`;

const StyledNavLink = styled(NavLink)`
  &:link,
  &:visited {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    color: #fff;

    font-size: 1.5rem;
    padding: 0.7rem 1rem;
    transition: all 0.3s;
    text-decoration: none;
    border-radius: 10px 0px 40px 10px;
  }

  &:hover,
  &:active,
  &.active:link,
  &.active:visited {
    color: #333;
    background-color: #fff;
  }
`;

const MainNav = () => {
  const { user } = useAppSelector((state: any) => state.loginuser);

  return (
    <>
      {user?.role === "admin" ? (
        <nav>
          <NavList>
            <li>
              <StyledNavLink to="/">
                <span>Home</span>
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="/admin/dashboard">
                <span>Admin Dashboard</span>
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="/admin/products">
                <span>All Products</span>
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="/admin/orders">
                <span>All Orders</span>
              </StyledNavLink>
            </li>

            <li>
              <StyledNavLink to="/admin/undeliveredorders">
                <span>Undelivered orders</span>
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="/admin/returnorders">
                <span>Returned Orders</span>
              </StyledNavLink>
            </li>
          </NavList>
        </nav>
      ) : user?.role === "vendor" ? (
        <nav>
          <NavList>
            <li>
              <StyledNavLink to="/">
                <span>Home</span>
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="/dashboard">
                <span>Dashboard</span>
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="/vendor/products">
                <span>All Products</span>
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="/vendor/NewProduct">
                <span>Create Product</span>
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="/vendor/orders">
                <span>All Orders</span>
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="/vendor/undeliveredorders">
                <span>Undelivered Orders</span>
              </StyledNavLink>
            </li>

            <li>
              <StyledNavLink to="/vendor/returnorders">
                <span>Returned Orders</span>
              </StyledNavLink>
            </li>
          </NavList>
        </nav>
      ) : (
        <nav>
          <NavList>
            <li>
              <StyledNavLink to="/">
                <span>Home</span>
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="search/jeans">
                <span>Jeans</span>
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="search/shirts">
                <span>Shirts</span>
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="search/t-shirts">
                <span>T-shirts</span>
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="search/sweatshirts">
                <span>Sweatshirts</span>
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="search/jackets">
                <span>Jackets</span>
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="search/joggers">
                <span>Joggers</span>
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="search/briefs">
                <span>Briefs</span>
              </StyledNavLink>
            </li>
          </NavList>
        </nav>
      )}
    </>
  );
};

export default MainNav;
