import { useAppDispatch, useAppSelector } from "../features/hooks/hooks";
import { useEffect } from "react";
import { getMyOrders } from "../features/action/orderAction";
import { NavLink } from "react-router-dom";
import Spinner from "../ui/Spinner";
import styled from "styled-components";
import { Link } from "react-router-dom";

const OrdersLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: #003366;
  }
`;

const OrdersContainer = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  margin: 10px;
  padding: 20px;
  display: flex;
  width: 95%;
  color: #333;
  border: 1px solid #dbdbdb;
  transition: box-shadow 0.1s linear;
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  /* justify-content: space-between; */
  text-decoration: none;
  overflow: hidden;

  &:hover {
    z-index: 1;
    box-shadow: 0 1px 12px 2px #dbdbdb;
  }

  @media screen and (max-width: 1020px) {
    width: 90%;
  }
  @media screen and (max-width: 480px) {
    width: 85%;
    margin: 10px 5px 5px 5px;
  }
`;
const DetailContainer = styled.div`
  display: flex;

  @media screen and (max-width: 480px) {
    display: grid;
    gap: 10px;
  }
`;
const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
  text-decoration: none;

  @media screen and (max-width: 480px) {
    display: grid;
    grid-template-columns: auto;
    gap: 10px;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  width: 90px;
  height: 70px;
`;
const ImgSizeContainer = styled.div`
  display: flex;
  gap: 1px;
  text-decoration: none;
`;
const ItemDetail = styled.div`
  display: grid;
  text-decoration: none;
`;
const ItemName = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
`;
const ItemSize = styled.span`
  font-family: "Times New Roman", Times, serif;
  text-decoration: none;
  color: gray;
`;
const ItemPrice = styled.span`
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  position: absolute;
  left: 50%;

  @media screen and (max-width: 480px) {
    position: absolute;
    left: 80%;
  }
`;
const DeliverStatus = styled.div`
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
`;
const StatusContainer = styled.div`
  position: absolute;
  left: 80%;

  @media screen and (max-width: 1020px) {
    left: 60%;
  }

  @media screen and (max-width: 480px) {
    position: relative;
    left: 20%;
  }
`;

const StatusMessage = styled.div`
  font-size: small;
  margin-top: 20px;

  @media screen and (max-width: 480px) {
    display: none;
  }
`;

const DeliverStatusIcon = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: none;
  border: 2px solid #26a541;
  display: inline-block;
  margin-right: 8px;
`;
const ReturnIcon = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: none;
  border: 2px solid #a52626;
  display: inline-block;
  margin-right: 8px;
`;

const EmptyCartMessage = styled.p`
  font-weight: 700;
  font-size: 18px;
  margin-top: 20px;
`;
const StyledEmpty = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 10px 20px;
  margin-top: 20px;

  background-color: #fff;
  cursor: pointer;
  border-radius: 5px;

  @media screen and (max-width: 480px) {
    margin-top: 90px;
  }
`;

const EmptyCartButton = styled(NavLink)`
  width: 40%;

  padding: 10px;
  /* margin-top: 50px; */
  text-decoration: none;
  font-size: 14px;
  border: none;
  border: 1px solid #ff3f6c;
  background-color: #fff;
  color: #ff3f6c;
  border-radius: 4px;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    text-decoration: none;
    background-color: #e32753;
    color: #fff;
  }
`;

const Orders = () => {
  const dispatch = useAppDispatch();
  const { orders, error, isLoading } = useAppSelector(
    (state: any) => state.orders
  );

  useEffect(() => {
    dispatch(getMyOrders());

    if (error) {
      console.error(error);
    }
  }, [error, dispatch]);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const orderComponent = (order: any) => {
    const deliverStatus = () => {
      if (order.orderStatus === "delivered") {
        return (
          <DeliverStatus>
            <span>
              <DeliverStatusIcon
                style={{ background: "#26a541" }}
              ></DeliverStatusIcon>
              Delivered on{" "}
              {months[new Date(order?.deliverdAt).getMonth()]?.slice(0, 3)}
              {new Date(order?.deliveredAt).getDate()},
              {new Date(order?.deliveredAt).getFullYear()}
            </span>
            <StatusMessage>Your Item has been Delivered</StatusMessage>
          </DeliverStatus>
        );
      } else if (order.orderStatus === "returned") {
        return (
          <DeliverStatus>
            <span>
              <ReturnIcon style={{ background: "#a52626" }}></ReturnIcon>
              Returned On{" "}
              {months[new Date(order?.return.createdAt).getMonth()]?.slice(
                0,
                3
              )}{" "}
              {new Date(order.return.createdAt).getDate()},{" "}
              {new Date(order.return.createdAt).getFullYear()}
            </span>
            <StatusMessage>Your Item has been Returned</StatusMessage>
          </DeliverStatus>
        );
      } else if (order.orderStatus === "return") {
        return (
          <DeliverStatus>
            <ReturnIcon></ReturnIcon>
            <span>Item will be returned soon</span>
            <StatusMessage>Return accepted</StatusMessage>
          </DeliverStatus>
        );
      } else
        return (
          <DeliverStatus>
            <DeliverStatusIcon></DeliverStatusIcon>
            <span>Delivery before tomorrow</span>
            <StatusMessage>Your Order has been placed.</StatusMessage>
          </DeliverStatus>
        );
    };

    return (
      <OrdersLink
        to={`/vendor/order/${order._id}`}
        // relative="path"
        // replace
        style={{ height: "165px" }}
      >
        <OrdersContainer>
          <ItemContainer>
            <DetailContainer>
              <ImgSizeContainer>
                <div>
                  <ImageContainer>
                    <img alt="" src={order?.picture || null} />
                  </ImageContainer>
                </div>
                <ItemDetail>
                  <ItemName>
                    <span>{order?.name || ""}</span>
                  </ItemName>
                  {order?.size ? (
                    <>
                      <ItemSize>
                        <span>Size: </span>
                        <span>{order?.size}</span>
                      </ItemSize>
                    </>
                  ) : null}
                  <span></span>
                </ItemDetail>
              </ImgSizeContainer>

              <ItemPrice>₹{order?.price.toLocaleString("en-IN")}</ItemPrice>
            </DetailContainer>
            <div>
              <StatusContainer>{deliverStatus()}</StatusContainer>
            </div>
          </ItemContainer>
        </OrdersContainer>
      </OrdersLink>
    );
  };

  return (
    <>
      {isLoading && !orders ? (
        <Spinner />
      ) : orders?.length > 0 ? (
        <div>
          {orders?.map((el: any, i: number) => (
            <div key={i}>{orderComponent(el)}</div>
          ))}
        </div>
      ) : (
        <StyledEmpty>
          <div>
            <div style={{ height: "165px", width: "146px" }}>
              <img
                src="https://constant.myntassets.com/checkout/assets/img/empty-bag.png"
                alt="image"
                style={{ width: "100%" }}
              />
            </div>

            <EmptyCartMessage>Hey, it feels so light!</EmptyCartMessage>
            <p>You've not ordered anything yet.</p>
            <div>
              <EmptyCartButton to="/cart">ORDER ITEM FROM CART</EmptyCartButton>
            </div>
          </div>
        </StyledEmpty>
      )}
    </>
  );
};

export default Orders;
