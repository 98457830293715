import axios from "axios";
import { BASE_API } from "../../utils/baseApi";

export const login: any =
  (token: any, name = "", method = "") =>
  async (dispatch: any) => {
    try {
      dispatch({
        type: "login/usersLoading",
        payload: true,
      });
      const obj = {
        withCredentials: true,
        headers: {
          Authorization: token,
          name,
          Method: method,
        },
      };
      const { data } = await axios.get(
        `${BASE_API}/api/v1/login/firebase`,
        obj
      );
      // console.log(data);
      await dispatch({
        type: "login/authentication",
        payload: true,
      });

      await dispatch({
        type: "login/loginSuccess",
        payload: data,
      });
      await dispatch({
        type: "login/usersLoading",
        payload: false,
      });
    } catch (error) {
      await dispatch({
        type: "login/usersLoading",
        payload: false,
      });
      console.error("Error : ", error);
    }
  };

export const loadUser = () => async (dispatch: any) => {
  try {
    await dispatch({
      type: "login/usersLoading",
      payload: true,
    });
    const { data } = await axios.get(`${BASE_API}/api/v1/me`, {
      withCredentials: true,
    });
    await dispatch({
      type: "login/loadUser",
      payload: data.user,
    });
    await dispatch({
      type: "login/authentication",
      payload: true,
    });
    await dispatch({
      type: "login/usersLoading",
      payload: false,
    });
  } catch (error) {
    console.log(error);
    await dispatch({
      type: "login/usersLoading",
      payload: false,
    });
  }
};

export const logoutUser = () => async (dispatch: any) => {
  try {
    dispatch({
      type: "login/usersLoading",
    });
    await axios.get(`${BASE_API}/api/v1/logout`);
    dispatch({
      type: "login/usersLoading",
    });
    dispatch({
      type: "login/loginSuccess",
      payload: { user: null },
    });
    dispatch({
      type: "login/authentication",
      payload: false,
    });
    window.location.reload();
  } catch (error) {
    console.log(error);
    dispatch({
      type: "login/usersLoading",
    });
  }
};
