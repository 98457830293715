import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface OrderState {
  orders: [] | null;
  isLoading: boolean;
  error: [] | null;
}

const initialState: OrderState = {
  orders: null,
  isLoading: false,
  error: null,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    orderLoading: (state) => {
      state.isLoading = !state.isLoading;
    },

    orderList: (state, action: PayloadAction<any>) => {
      state.orders = action.payload.order;
    },
    orderError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
  },
});

export default orderSlice.reducer;
