import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ProductState {
  product: [] | null;
  isLoading: boolean;
  resPerPage: number | null;
  productsCount: number | null;

  error: [] | null;
}

const initialState: ProductState = {
  product: [],
  isLoading: false,
  resPerPage: null,
  productsCount: null,
  error: null,
};

const productSlice: any = createSlice({
  name: "product",
  initialState,
  reducers: {
    productsLoading: (state) => {
      state.isLoading = !state.isLoading;
    },
    productsList: (state, action: PayloadAction<any>) => {
      state.product = action.payload.message;
      state.resPerPage = action.payload.resPerPage;
      state.productsCount = action.payload.productsCount;
    },
    productsError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default productSlice.reducer;
