import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import styled from "styled-components";
import { updateCart } from "../action/cartAction";

import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const ProductDetailContainer = styled.div`
  display: flex;
  margin-top: 30px;
  font-family: Arial, Helvetica, sans-serif;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const ProductImageContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 480px) {
    margin: 0 10px;
  }
`;

const ProductImage = styled.img`
  max-width: 100%;
  max-height: 500px;
  margin-bottom: 20px;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
`;

const ThumbnailContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const ThumbnailImage = styled.img`
  max-width: 60px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.2s ease-in-out;

  &:hover {
    border-color: #333;
  }
`;
const CaraouselImage = styled.img`
  width: 40vw;
`;
const Category = styled.span`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;

  @media screen and (max-width: 768px) {
    display: flex;
    margin: 50px 0 0 0;
  }
`;
const ProductInfoContainer = styled.div`
  flex: 1;
  padding: 0 20px;
`;

const ProductName = styled.p`
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 25px;
  font-style: initial;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const ItemMrp = styled.span`
  text-decoration: line-through;
  font-size: medium;
  margin: 0 1%;
`;
const Price = styled.span`
  font-size: larger;
  font-weight: 600;
`;

const Discount = styled.span`
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 25px;
  font-style: initial;
  color: red;
`;

const ProductSize = styled.div`
  display: flex;
  gap: 10px;
  font-size: 16px;
  margin-bottom: 20px;
`;
const StyledStatus = styled.p`
  display: flex;
  gap: 2%;
  flex-direction: row;
  font-size: larger;
  width: 100%;
  background-color: #fff;
  position: relative;
`;

const AddToCartButton = styled.button`
  background-color: #fff;

  color: black;
  border: 2px solid #b2b2b2;
  padding: 10px 5px;
  width: 50%;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;

  &:hover {
    border: 2px solid gray;
  }

  @media screen and (max-width: 480px) {
    position: relative;
    right: 10px;
    width: 45%;
  }
`;

const SizeInputs = styled.p`
  font-size: small;
  position: absolute;
  border: none;
  text-decoration: none;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  cursor: pointer;
`;

const SizeDiv = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 40px;
  background-color: #fff;
  border-radius: 50%;
  border: solid #666;
  position: relative;
`;
const BuyNowButton = styled.button`
  background-color: #ff3f6c;
  color: #333;
  border: 2px solid red;
  padding: 10px;
  width: 45%;

  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    color: #fff;
    background-color: #e32753;
  }

  @media screen and (max-width: 480px) {
    position: relative;
    left: 10px;
    width: 45%;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1%;
  margin-top: 10px;
`;

const Point = styled.p`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #26a541;
  border: 2px solid #26a541;
  display: inline-block;
  margin-right: 5px;
`;

interface Product {
  name: string;
  size: any;
  images: { url: string }[];
  _id: string;
  inStock: boolean;
  description: string;
  category: string;
}

interface ProductDetailProps {
  product: Product;
}

const ProductDetail: React.FC<ProductDetailProps> = ({
  product,
}: {
  product: any;
}) => {
  const [selectedSize, setSelectedSize] = useState("");
  const [mainImage, setMainImage] = useState(product.images[0].url);
  const dispatch = useAppDispatch();
  const alert = useAlert();

  const navigate = useNavigate();
  const { isAuthenticated } = useAppSelector((state: any) => state.loginuser);

  const cartState = useAppSelector((state: any) => state.cart);

  const commaNumber = require("comma-number");

  const handleSizeChange = async (size: any) => {
    await setSelectedSize(size);
    console.log(selectedSize);
  };

  const handleThumbnailClick = (thumbnailUrl: string) => {
    setMainImage(thumbnailUrl);
  };

  async function addToCart(size: any, productId: any) {
    if (!isAuthenticated) {
      return alert.error("Login first to access this resource");
    }

    if (product?.size?.length > 0 && !selectedSize) {
      return alert.error("Please select a size");
    }

    if (product?.inStock === false) {
      return alert.error("Product out of stock");
    }

    await dispatch(updateCart(size, productId));

    if (cartState.error)
      return alert.error("Product already in cart", { timeout: 3000 });
    if (!cartState.error)
      return alert.show("Product added to cart", { timeout: 3000 });
  }

  const buynow = (e: any) => {
    e.preventDefault();
    if (!isAuthenticated) {
      return alert.error("Login first to access this resource");
    } else if (product?.size?.length > 0 && !selectedSize) {
      return alert.error("Please select a size");
    } else if (product?.inStock === false) {
      return alert.error("Product out of stock");
    } else {
      return navigate(
        `/shipping/${window.btoa(product?._id)}?EzZTAz=${window.btoa(
          selectedSize
        )}`
      );
    }
  };

  return (
    <ProductDetailContainer>
      <ProductImageContainer>
        <ProductImage src={mainImage} alt={product.name} />
        <ThumbnailContainer>
          {product.images.map((image: any, index: number) => (
            <ThumbnailImage
              key={index}
              src={image.url}
              alt={`Thumbnail ${index + 1}`}
              onClick={() => handleThumbnailClick(image.url)}
            />
          ))}
        </ThumbnailContainer>
      </ProductImageContainer>
      <ProductInfoContainer>
        <Category>Category: {product?.category}</Category>
        <ProductName>{product?.name}</ProductName>
        <div>
          <p>
            <span>
              <Price>{`₹ ${commaNumber(product?.price)}`}</Price>
              {product?.MRPprice ? (
                <>
                  <ItemMrp> ₹{commaNumber(product?.MRPprice)} </ItemMrp>
                  <Discount>
                    {" "}
                    (
                    {commaNumber(
                      Math.trunc(
                        ((product?.MRPprice - product?.price) * 100) /
                          product?.MRPprice
                      )
                    )}
                    %)
                  </Discount>
                </>
              ) : (
                ""
              )}
              <br />
            </span>
            <span style={{ color: "#18c220" }}>Inclusive of all taxes</span>
          </p>
          <StyledStatus>
            Status:{" "}
            {product?.inStock === true ? (
              <span style={{ color: "#18c220" }}>In Stock</span>
            ) : (
              <span style={{ color: "red" }}>Out of Stock</span>
            )}
          </StyledStatus>
        </div>

        <ProductSize>
          {product?.size?.map((sizeOption: any, index: number) => (
            <SizeDiv
              key={index}
              style={{
                border:
                  String(selectedSize) === String(sizeOption)
                    ? " solid  #003366"
                    : " 1px solid #666",
              }}
              onClick={() => handleSizeChange(sizeOption)}
            >
              <SizeInputs>{sizeOption}</SizeInputs>
            </SizeDiv>
          ))}
        </ProductSize>
        <div>
          <AddToCartButton
            disabled={product?.inStock === true ? false : true}
            onClick={() => addToCart(selectedSize, product._id)}
          >
            Add to Cart
          </AddToCartButton>
          <BuyNowButton onClick={(e) => buynow(e)}>Buy Now</BuyNowButton>
        </div>

        {product?.description ? (
          <Description>
            <Point></Point>
            <p>{product?.description}</p>
          </Description>
        ) : (
          ""
        )}
      </ProductInfoContainer>
    </ProductDetailContainer>
  );
};

export default ProductDetail;
