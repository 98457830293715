import { FaArrowCircleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 40px;
`;

const Image = styled.img`
  width: 40%;
`;

const DivContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  font-size: large;
  width: 90%;
  margin-top: 10px;
  padding: 10px 0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: #008dd3;
  border: 2px solid #2da7e4;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #2da7e4;
  }
`;

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <PageContainer>
      <Image src="https://i0.wp.com/www.msnoob.com/wp-content/uploads/2018/12/error-404.jpg?resize=696%2C653&ssl=1" />
      <DivContainer>
        <h3>This page could not be found</h3>
        <Button onClick={() => navigate("/")}>
          {" "}
          <FaArrowCircleLeft size={25} /> Back To Home
        </Button>
      </DivContainer>
    </PageContainer>
  );
};

export default PageNotFound;
