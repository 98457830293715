import { initializeApp } from "firebase/app";

import { login } from "../action/loginAction";
// import store from "../../store";
import {
  getAuth,
  GoogleAuthProvider,
  getRedirectResult,
  onAuthStateChanged,
  signInWithPopup,
} from "firebase/auth";
import store from "../../store";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyDWNdUs2BuaajEYLrFN0lOmZfVuSxmwlb8",
  authDomain: "shopibye-463f4.firebaseapp.com",
  projectId: "shopibye-463f4",
  storageBucket: "shopibye-463f4.appspot.com",
  messagingSenderId: "617506796922",
  appId: "1:617506796922:web:2d61e564d6f4351dfed2c3",
  measurementId: "G-25W3XHE0YS",
});

export const auth = getAuth(firebaseApp);
// console.log(auth);
export const provider = new GoogleAuthProvider();

onAuthStateChanged(auth, (user: any) => {
  if (user) {
    store.dispatch(login(user.accessToken, "", "email"));
    console.log("User");
  } else {
    console.log("User signed out");
  }
});
// console.log(onAuthStateChanged);
getRedirectResult(auth)
  .then(async (result) => {
    // await axios.get(`http://localhost:8080/api/v1/login/firebase`, {
    //   // withCredentials: true,
    //   headers: {
    //     // Authorization: result!.user.accessToken,
    //     Method: "email",
    //   },
    // });
    const credential = GoogleAuthProvider.credentialFromResult(result!);
    // const token = credential!.accessToken;

    // console.log(user, user.accessToken);
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    const email = error.email;
    const credential = GoogleAuthProvider.credentialFromError(error);
    console.log(errorCode, errorMessage, email, credential);
  });

const signIn = (): void => {
  signInWithPopup(auth, provider)
    .then((result: any) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential!.accessToken;
      store.dispatch(login(result.user.accessToken, "", "email"));

      // The signed-in user info.
      const user = result.user;
      // console.log(user);
      // ...
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;

      const credential = GoogleAuthProvider.credentialFromError(error);
      console.log(errorCode, errorMessage, email, credential);
    });
};

export default signIn;
