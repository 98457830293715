import React from "react";
import Spinner from "./Spinner";
import { FaTimes } from "react-icons/fa";
import styled from "styled-components";

const ProductContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 20px 0;
  justify-content: space-between;
`;

const WishListButton = styled.button`
  position: relative;
  top: 45%;
  height: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    color: #ff1a1a;
  }
`;

const Image = styled.img`
  width: 100px;
`;

interface ConfirmOrderComponentProps {
  moveToWishlist: (productId: string) => void;
  product: {
    _id: string;
    images: Array<{ url: any }>;
    price: number;
    inStock: boolean;
  };
  loading: boolean;
  size?: string;
}
const ConfirmOrderComponent: React.FC<ConfirmOrderComponentProps> = ({
  moveToWishlist,
  product,
  loading,
  size,
}) => {
  console.log(product?._id);
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <ProductContainer>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Image
              src={product?.images?.length && product?.images[0]?.url}
              alt="product"
            />

            <div>
              {size ? (
                <>
                  {" "}
                  <span> ₹{product?.price} - </span>
                  <span>Size {size}</span>
                </>
              ) : (
                <span> ₹{product?.price} </span>
              )}
              {product?.inStock === true ? "" : <span> Out of Stock </span>}
            </div>
          </div>

          <WishListButton onClick={() => moveToWishlist(product._id)}>
            <FaTimes size={25} />
          </WishListButton>
        </ProductContainer>
      )}
    </>
  );
};

export default ConfirmOrderComponent;
