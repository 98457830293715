import styled from "styled-components";

const ContainerDiv = styled.div`
  position: relative;
  grid-column: span 10 / span 10;
  grid-column-start: 1;
  font-family: "Poppins", sans-serif;
  width: 100%;
  margin-top: 20px;
  margin-left: 0;

  @media (min-width: 768px) {
    grid-column: span 3 / span 3;
    grid-column-start: 7;
  }
`;

const SemiContainer = styled.div`
  position: static;
  padding-bottom: 8px;
  border-bottom: 1px solid #f5f5f6;
`;
const PriceBlockHeader = styled.div`
  padding-bottom: 8px;
  font-weight: 600;
  border-bottom: 1px solid #f5f5f6;
`;
const AllProductPrice = styled.div`
  display: flex;
  padding: 8px;
  justify-content: space-between;
  border-bottom: 2px solid #f5f5f6;
`;
const DeliverPrice = styled.span`
  text-decoration: line-through;
  margin-right: 5px;
`;
const FreeDeliveryText = styled.span`
  display: flex;
  padding: 8px 8px;
  background-color: #f2faf9;
`;

const TotalPrice = styled.div`
  display: flex;
  font-size: 17px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  padding: 8px;
  justify-content: space-between;
  border-bottom: 2px solid #f5f5f6;
`;

const PlaceOrderButton = styled.button`
  width: 100%;
  text-align: center;
  padding: 10px;
  float: right;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid #d4d5d9;
  background-color: #ff3f6c;
  color: #fff;
  border-radius: 4px;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* overflow: hidden; */

  &:hover {
    text-decoration: none;
    background-color: #e32753;
  }
`;

interface ConfirmOrderComponentProps {
  numberOfItems: string;
  Mrp: string;
  discount: number | null | string;
  totalAmount: string;
  placeOrder: any;
}

const ConfirmOrderComponent: React.FC<ConfirmOrderComponentProps> = ({
  numberOfItems,
  Mrp,
  discount,
  totalAmount,
  placeOrder,
}) => {
  return (
    <>
      <ContainerDiv>
        <SemiContainer>
          <PriceBlockHeader>
            PRICE DETAILS ({numberOfItems} Items)
          </PriceBlockHeader>
          <div>
            <div>
              <AllProductPrice>
                <span>Total MRP</span>
                <span>
                  <span></span>

                  <span>
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="10"
                      viewBox="0 0 8 10"
                      className="priceDetail-base-icon inline mr-1"
                    >
                      <path
                        fillRule="nonzero"
                        d="M5.841 9.44l-1.21.513L.365 5.968l.547-.875c.251-.005.529-.033.834-.086a4.91 4.91 0 0 0 .896-.242c.292-.11.556-.251.793-.424s.412-.383.526-.63H.564V2.578h3.431a1.779 1.779 0 0 0-.307-.51 1.99 1.99 0 0 0-.52-.426 2.789 2.789 0 0 0-.738-.29 3.955 3.955 0 0 0-.957-.107h-.91V0h6.31v1.244H4.522c.082.082.162.179.239.29a3.867 3.867 0 0 1 .39.712c.05.12.086.23.109.331h1.613v1.135H5.246a2.51 2.51 0 0 1-.434.906 3.293 3.293 0 0 1-.718.694 3.884 3.884 0 0 1-.916.478 5.378 5.378 0 0 1-1.028.267L5.84 9.44z"
                      ></path>
                    </svg>
                    {Mrp}
                  </span>
                </span>
              </AllProductPrice>
              <AllProductPrice>
                <span>Discount on MRP</span>
                {discount ? (
                  <span style={{ color: "green" }}>
                    <span>
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="10"
                        viewBox="0 0 8 10"
                      >
                        <path
                          fillRule="nonzero"
                          d="M5.841 9.44l-1.21.513L.365 5.968l.547-.875c.251-.005.529-.033.834-.086a4.91 4.91 0 0 0 .896-.242c.292-.11.556-.251.793-.424s.412-.383.526-.63H.564V2.578h3.431a1.779 1.779 0 0 0-.307-.51 1.99 1.99 0 0 0-.52-.426 2.789 2.789 0 0 0-.738-.29 3.955 3.955 0 0 0-.957-.107h-.91V0h6.31v1.244H4.522c.082.082.162.179.239.29a3.867 3.867 0 0 1 .39.712c.05.12.086.23.109.331h1.613v1.135H5.246a2.51 2.51 0 0 1-.434.906 3.293 3.293 0 0 1-.718.694 3.884 3.884 0 0 1-.916.478 5.378 5.378 0 0 1-1.028.267L5.84 9.44z"
                        ></path>
                      </svg>
                      {discount}
                    </span>
                  </span>
                ) : (
                  <span>
                    <span>-</span>
                  </span>
                )}
              </AllProductPrice>

              <AllProductPrice>
                <span>Delivery Charge</span>
                <span>
                  <span>
                    <DeliverPrice>₹30</DeliverPrice>
                  </span>
                  <span style={{ color: "green" }}>FREE</span>
                </span>
              </AllProductPrice>
              <TotalPrice>
                <span>Total Amount</span>
                <span>
                  <span></span>

                  <span>
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="10"
                      viewBox="0 0 8 10"
                    >
                      <path
                        fillRule="nonzero"
                        d="M5.841 9.44l-1.21.513L.365 5.968l.547-.875c.251-.005.529-.033.834-.086a4.91 4.91 0 0 0 .896-.242c.292-.11.556-.251.793-.424s.412-.383.526-.63H.564V2.578h3.431a1.779 1.779 0 0 0-.307-.51 1.99 1.99 0 0 0-.52-.426 2.789 2.789 0 0 0-.738-.29 3.955 3.955 0 0 0-.957-.107h-.91V0h6.31v1.244H4.522c.082.082.162.179.239.29a3.867 3.867 0 0 1 .39.712c.05.12.086.23.109.331h1.613v1.135H5.246a2.51 2.51 0 0 1-.434.906 3.293 3.293 0 0 1-.718.694 3.884 3.884 0 0 1-.916.478 5.378 5.378 0 0 1-1.028.267L5.84 9.44z"
                      ></path>
                    </svg>
                    {totalAmount}
                  </span>
                </span>
              </TotalPrice>
            </div>
          </div>

          <FreeDeliveryText>
            <div>
              <span>Yay!</span>
              <span style={{ color: "green" }}>No Delivery Fee</span>
              <DeliverPrice>₹30</DeliverPrice>
              on this order
            </div>
          </FreeDeliveryText>

          <PlaceOrderButton onClick={(e) => placeOrder(e)} type="submit">
            Place Order
          </PlaceOrderButton>
        </SemiContainer>
      </ContainerDiv>
    </>
  );
};

export default ConfirmOrderComponent;
