import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const SearchContainer = styled.form<{ isopen?: string }>`
  display: flex;
  align-items: center;

  background-color: #fff;
  border: 1px solid #ccc;
  width: 60%;
  border-radius: 5px;
  padding: 5px 10px;

  box-shadow: 1px 4px 10px #ccc;
  @media (max-width: 480px) {
    display: none;
    width: 100%;
    display: ${({ isopen }) =>
      isopen === "true" ? "flex" : "none"} !important;
  }
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  padding: 10px 20px;
  font-size: 14px;

  @media (max-width: 480px) {
    padding: 10px 40px;
  }
`;

const SearchButton = styled.button`
  background-color: transparent;

  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #eee;
  }

  @media (max-width: 480px) {
    position: relative;
    right: 10px;
  }
`;

interface SearchBarProps {
  isopen?: string;
  setIsOpen: any;
}

const SearchBar: React.FC<SearchBarProps> = ({ isopen, setIsOpen }) => {
  const [keyword, Setkeyword] = useState("");
  const navigate = useNavigate();
  const searchHandler = (e: any) => {
    // console.log(keyword);
    e.preventDefault();

    setIsOpen();
    if (keyword.trim()) {
      return navigate(`/search/${keyword}`);
    }
  };

  return (
    <SearchContainer onSubmit={searchHandler} isopen={isopen}>
      <SearchInput
        type="text"
        placeholder="Search..."
        onChange={(e) => Setkeyword(e.target.value)}
      />
      <SearchButton onSubmit={searchHandler} type="submit">
        <FaSearch size={16} />
      </SearchButton>
    </SearchContainer>
  );
};

export default SearchBar;
