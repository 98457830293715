import React, { useEffect } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { useAlert } from "react-alert";
import Spinner from "./Spinner";

import ConfirmOrderComponent from "./ConfirmOrderComponent";
import ConfirmOrderPriceComponent from "./ConfirmOrderPriceComponent";
import { deleteCart } from "../features/action/cartAction";
import { getProductDetail } from "../features/action/productAction";
import { createOrders } from "../features/action/orderAction";
import { useAppDispatch, useAppSelector } from "../features/hooks/hooks";
import styled from "styled-components";
import { loadUser } from "../features/action/loginAction";
import { BASE_API } from "../utils/baseApi";

const ConfirmOrderContainer = styled.div`
  margin: 50px 120px 50px 120px;
  font-family: "Poppins", sans-serif;
  overflow: hidden;

  @media screen and (max-width: 1020px) {
    margin: 50px 0;
  }
`;

const ChangeAddress = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  margin: 20px 0;
  border-radius: 5px;
  color: #555;
  border: 1px solid #444;
  text-decoration: none;
  overflow: hidden;

  &:hover {
    color: #003366;
    text-decoration: underline;
  }
`;
const AddressContainer = styled.div`
  font-family: "Poppins", sans-serif;
`;

const Name = styled.div`
  font-size: large;
  font-weight: 600;
  margin-bottom: 2px;
`;

const DetailContainer = styled.div`
  display: grid;

  @media screen and (max-width: 1020px) {
    display: block;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

function ConfirmOrder() {
  const dispatch = useAppDispatch();
  const alert = useAlert();
  const navigate = useNavigate();
  const location = useLocation();
  const param = useParams();

  const { cartproduct: cart, isLoading: cartLoading } = useAppSelector(
    (state: any) => state.cart
  );

  const { user, isLoading } = useAppSelector((state: any) => state.loginuser);

  const { product, isloading: productLoading } = useAppSelector(
    (state: any) => state.productDetail
  );

  let params = new URLSearchParams(location.search);

  useEffect(() => {
    if (param.productId) {
      dispatch(getProductDetail(window.atob(param.productId)));
    }
  }, [dispatch, alert, param.productId]);

  useEffect(() => {
    // console.log("JUHUBEACH");
    dispatch(loadUser());
  }, [dispatch]);

  useEffect(() => {
    if (!param.productId && cartLoading === false && cart?.length < 1) {
      alert.error("Please add items in cart before checkout");

      navigate("/");
    }
  }, [dispatch, alert, cart, cartLoading]);

  useEffect(() => {
    if (param.productId && productLoading === false && !product?.product?._id) {
      alert.error("Something went wrong, Please try again later");

      navigate("/");
    }
  }, [dispatch, alert, param.id, isLoading, product]);

  const reducer = (previousValue: any, currentValue: any) => {
    return previousValue + currentValue?.product?.price;
  };

  const mrpReducer = (previousValue: any, currentValue: any) => {
    return (
      previousValue +
      (currentValue?.product?.MRPprice || currentValue?.product?.price)
    );
  };

  const moveToWishlist = async (cartId: any) => {
    await axios.put(
      `${BASE_API}/api/v1/wishlist`,
      {
        productId: cartId,
      },
      { withCredentials: true }
    );
    dispatch(deleteCart(cartId));
  };
  const order = cart?.map(({ product, size }: { product: any; size: any }) => {
    return {
      name: product?.name,
      product: product?._id,
      price: product?.price,
      vendor: product?.user,
      quantity: 1,
      orignalPrice: product?.MRPprice,
      picture: product?.images[0]?.url,
      shippingInfo: user?.userAddress,
      size: size,
    };
  });
  const isOutofStock = () => {
    return cart?.find((el: any) => el?.product?.inStock === false);
  };
  const placeOrder = (e: any) => {
    if (
      user?.userAddress?.address &&
      user?.userAddress?.landmark &&
      user?.userAddress?.number
    ) {
      if (param.productId) {
        if (product?.product?.inStock === false)
          alert.error("product out of stock");
        dispatch(
          createOrders([
            {
              name: product?.name,
              product: product?._id,
              price: product?.price,
              vendor: product.user,
              quantity: 1,
              orignalPrice: product?.MRPprice,
              picture: product?.images[0]?.url,
              shippingInfo: user.userAddress,
              size: window.atob(params.get("EzZTAz") ?? ""),
            },
          ])
        );
      } else {
        if (isOutofStock()) return alert.error("A product is out of stock");
        dispatch(createOrders(order));
      }

      alert.success("Order Has been placed successfully");
      navigate("/");
    } else alert.error("Please add a valid address");
  };

  return (
    <>
      {productLoading || isLoading ? (
        <p>
          {" "}
          <Spinner />{" "}
        </p>
      ) : (
        <ConfirmOrderContainer>
          <div>
            <div>
              <AddressContainer>
                <Name>{user?.name}</Name>

                <div style={{ margin: "5px 0" }}>
                  {user?.userAddress?.address}

                  <div style={{ margin: "5px 0" }}>
                    {user?.userAddress?.landmark}
                  </div>

                  <span style={{ margin: "5px 0" }}>Mobile:</span>
                  <span> {user?.userAddress?.number}</span>
                </div>
              </AddressContainer>
              <ChangeAddress to={`/shipping/${param?.productId || ""}`}>
                CHANGE ADDRESS
              </ChangeAddress>
            </div>
            <DetailContainer>
              <ImageContainer>
                {param?.productId ? (
                  <ConfirmOrderComponent
                    moveToWishlist={moveToWishlist}
                    loading={isLoading}
                    product={product}
                    size={window.atob(params.get("EzZTAz") ?? "")}
                  />
                ) : (
                  cart?.map((el: any) => {
                    return (
                      <ConfirmOrderComponent
                        moveToWishlist={moveToWishlist}
                        product={el.product}
                        loading={isLoading}
                        size={el.size}
                      />
                    );
                  })
                )}
              </ImageContainer>
              <div>
                {param?.productId && product ? (
                  <ConfirmOrderPriceComponent
                    numberOfItems="1"
                    Mrp={(
                      product?.MRPprice ||
                      product?.price ||
                      0
                    ).toLocaleString("en-IN")}
                    discount={
                      product?.MRPprice - product?.price > 0
                        ? (product?.MRPprice - product?.price).toLocaleString(
                            "en-IN"
                          )
                        : 0
                    }
                    totalAmount={product?.price?.toLocaleString("en-IN")}
                    placeOrder={placeOrder}
                  />
                ) : (
                  <ConfirmOrderPriceComponent
                    numberOfItems={cart?.length}
                    Mrp={Math.round(
                      cart?.reduce(mrpReducer, 0)
                    )?.toLocaleString("en-IN")}
                    discount={Math.round(
                      cart?.reduce(mrpReducer, 0) - cart?.reduce(reducer, 0)
                    )?.toLocaleString("en-IN")}
                    totalAmount={Math.round(
                      cart?.reduce(reducer, 0)
                    )?.toLocaleString("en-IN")}
                    placeOrder={placeOrder}
                  />
                )}
              </div>
            </DetailContainer>
          </div>
        </ConfirmOrderContainer>
      )}
    </>
  );
}

export default ConfirmOrder;
