import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { setProductStock, updateProduct } from "../action/productAction";

interface NewProductState {
  product: [] | null;
  isLoading: boolean;
  stock: boolean;
  isUpdated: boolean;
  error: [] | null;
  updateError: [] | null;
}

const initialState: NewProductState = {
  product: [],
  isLoading: false,
  isUpdated: false,
  error: null,
  stock: false,
  updateError: null,
};

const newProductSlice: any = createSlice({
  name: "newProduct",
  initialState,
  reducers: {
    newProductLoading: (state) => {
      state.isLoading = !state.isLoading;
    },
    newProduct: (state, action: PayloadAction<any>) => {
      state.product = action.payload.product;
    },
    newProductError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateProduct.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateProduct.fulfilled, (state, action) => {
      state.isUpdated = action.payload;
      state.isLoading = false;
    });
    builder.addCase(updateProduct.rejected, (state: any, action: any) => {
      state.isLoading = false;
      state.updateError = action.payload;
    });
    builder.addCase(setProductStock.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(setProductStock.fulfilled, (state, action) => {
      state.stock = action.payload;
      state.isLoading = false;
    });
    builder.addCase(setProductStock.rejected, (state: any, action: any) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default newProductSlice.reducer;
