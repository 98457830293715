import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ProductCard from "./ProductCard";
import { productsList } from "../action/productAction";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { getWishList } from "../action/wishlistAction";
import Spinner from "../../ui/Spinner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import LoginModal from "../auth/LoginModal";

const ProductListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;
const PaginationStyle = styled(Pagination)``;

const PaginationContainer = styled.div`
  ul.pagination {
    display: flex;
    justify-content: center;

    list-style: none;

    @media screen and (max-width: 480px) {
      position: relative;
      right: 30px;
    }
  }

  li.page-item {
    margin: 0.2rem;
    padding: 0.2rem;

    height: 10%;
    width: 5%;
    align-items: center;
    text-align: center;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #333;
    @media screen and (max-width: 480px) {
      width: 10%;
    }

    &.active {
      background-color: #007bff;

      :-webkit-any-link {
        color: #fff;
      }
    }

    &:hover {
      background-color: #efefef;

      :-webkit-any-link {
        color: #007bff;
      }
    }
  }

  a.page-link {
    text-decoration: none;
  }
`;

interface Product {
  _id?: string;
  name?: string;
  images?: { url: string }[];
  price?: number;
  MRPprice?: number;
  seller?: string;
}

interface login {
  Login?: boolean;
}

const ProductList: React.FC<login> = ({ Login }) => {
  // const [productList, setProductList] = useState<Product[] | null>(null);
  const location = useLocation();
  let params = new URLSearchParams(location.search);

  const { keyword } = useParams();

  let {
    product: productList,
    isLoading,
    resPerPage,
    productsCount,
  } = useAppSelector((state: any) => state.products);
  let { wishlistProduct: wishList } = useAppSelector(
    (state: any) => state.wishList
  );
  let wishlistLoading = useAppSelector(
    (state: any) => state.wishList.isLoading
  );
  const dispatch = useAppDispatch();
  const [productListWish, setProductListWish] = useState<Product[] | null>(
    null
  );
  const navigate = useNavigate();

  const [open, setOpen] = useState(Login);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function setCurrentPageNo(pageNumber: any) {
    params.set("p%young", pageNumber);
    return navigate(`${location.pathname}?${params}`);
  }
  useEffect(() => {
    let productList1 = productList;
    if (typeof wishList === "object" && wishList?.length > 0 && productList) {
      wishList = wishList.map((el: any) => el._id);
      productList1 = productList.map((el: any) =>
        wishList.includes(el._id)
          ? { ...el, isWishlisted: "true" }
          : { ...el, isWishlisted: "false" }
      );
    }
    setProductListWish(productList1);
  }, [isLoading, productList, wishlistLoading, wishList]);

  useEffect(() => {
    async function item() {
      // if (!(productList && productList.length))
      await dispatch(
        productsList(
          keyword,
          params.get("p%young"),
          [Number(params.get("p_A")) || 0, Number(params.get("p_B")) || 0],
          params.get("cat"),
          params.get("sort")
        )
      );
      // console.log(productList, 1);

      if (!(wishList && wishList.length)) await dispatch(getWishList());
      // console.log(wishList, 2);
    }
    item();
  }, [dispatch, keyword, params.get("p%young")]);

  if (isLoading) return <Spinner />;

  return (
    <>
      {" "}
      <LoginModal
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
      />
      <ProductListContainer>
        {productListWish &&
          productListWish.length > 0 &&
          productListWish.map((product: any) => (
            <ProductCard key={product._id} product={product} />
          ))}
      </ProductListContainer>{" "}
      {resPerPage < productsCount ? (
        <PaginationContainer>
          <PaginationStyle
            activePage={Number(params.get("p%young")) || 1}
            itemsCountPerPage={resPerPage}
            totalItemsCount={productsCount}
            onChange={setCurrentPageNo}
            firstPageText={"First"}
            lastPageText={"Last"}
            pageRangeDisplayed={4}
            itemClass="page-item"
            linkClass=" page-link"
          />
        </PaginationContainer>
      ) : null}
    </>
  );
};

export default ProductList;
