import { useState, useEffect } from "react";
import Spinner from "../../../ui/Spinner";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useAlert } from "react-alert";
import {
  getOrderDetails,
  returnOrder,
  updateOrder,
} from "../../action/orderAction";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { BASE_API } from "../../../utils/baseApi";

const OrderDetails = styled.div`
  margin-top: 10px;
  display: grid;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;

  @media screen and (max-width: 480px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;

    /* position: relative; */
  }
`;

const OrderContainer = styled.div`
  @media screen and (max-width: 1020px) and (min-width: 481px) {
    width: 400px;
  }
`;

const OrderPicture = styled.picture`
  display: grid;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: 10px;

  @media screen and (max-width: 480px) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;

    top: 10%;

    /* position: relative; */
  }
`;

const DeliveryContainer = styled.div`
  display: grid;
  width: 800px;
  gap: 5px;
  border-radius: 5px;
  padding: 10px 0 20px 5px;
  border: 1px solid green;
  color: #fff;
  background-color: #03a685;
  margin: 10px 0 10px 0;

  @media screen and (max-width: 1020px) and (min-width: 481px) {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    position: relative;
    left: 15%;
  }
`;

const CancelButton = styled.button`
  border: none;
  border-radius: 5px;
  padding: 10px 0px;
  width: 800px;
  background-color: #ff3f6c;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #ff184e;
  }
  @media screen and (max-width: 1020px) and (min-width: 481px) {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    position: relative;
    left: 20%;
  }
`;

const ReturnButton = styled.button`
  border: none;
  border-radius: 5px;
  padding: 10px 0px;
  width: 800px;
  background-color: #ff3f6c;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #ff184e;
  }

  @media screen and (max-width: 1020px) and (min-width: 481px) {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    left: 20%;
  }
`;

const AddressDetail = styled.div`
  padding: 30px 0 20px 5px;
  display: grid;
  border-bottom: 2px solid gray;
  gap: 10px;

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

const PriceDetailContainer = styled.div`
  margin: 10px 0 10px 0;
  padding: 20px 5px 10px 5px;
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    /* position: relative; */
  }
`;

const ProductPrice = styled.div`
  display: grid;
  padding: 30px 0 20px 0;
  gap: 10px;

  @media screen and (max-width: 480px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const PriceSpan = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Mrp = styled.span`
  text-decoration: line-through;
`;

const ShippingFee = styled.span`
  color: green;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 0 0 20px 0; */
  width: 100%;

  @media screen and (max-width: 480px) {
    justify-content: center;
  }
`;

const SelectOption = styled.select`
  width: 30%;
  height: 40px;
  margin-bottom: 10px;
  border: 2px solid #9999;
  border-radius: 5px;
  cursor: pointer;
`;

const UpdateButton = styled.button`
  border: none;
  border-radius: 5px;
  padding: 15px 0;
  background-color: #2747e6;
  color: #fff;
  cursor: pointer;
  width: 800px;

  &:hover {
    background-color: #0027ea;
  }

  @media screen and (max-width: 1020px) and (min-width: 481px) {
    width: 400px;
  }

  @media screen and (max-width: 480px) {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    position: relative;
    left: 10%;
  }
`;

const ProcessOrder = () => {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [status, setStatus] = useState("");

  const alert = useAlert();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { orderDetail, isLoading, error, isUpdated } = useAppSelector(
    (state: any) => state.orderDetail
  );
  const { user } = useAppSelector((state: any) => state.loginuser);

  // const [showCancel, setShowCancel] = useState(false);
  // const [showReturn, setShowReturn] = useState(false);
  const location = useLocation();
  const orderId = location.pathname.split("/").pop();

  useEffect(() => {
    dispatch(getOrderDetails(orderId));

    if (isUpdated) {
      alert.success("Order Updated successfully");
      console.log("SUCCESS");
    }
  }, [dispatch, alert]);

  const updateOrderHandler = (id: string) => {
    const formData: any = new FormData();
    formData.set("status", status);
    // console.log(formData);
    // console.log("SUCCESS");
    dispatch(updateOrder({ id: id, orderData: formData }));
  };

  const exchangeOrder = (e: any) => {
    e.preventDefault();
    const id = orderDetail?._id;
    console.log(id);
    dispatch(returnOrder({ type: "return", idww: id }));
    alert.success("Product Returned");

    navigate("/");
  };

  const isValid: any = () => {
    if (error === "You do not have permission to access other user's order")
      navigate("/");
  };

  const cancelOrder = async (e: Event) => {
    e.preventDefault();

    await axios.delete(
      `${BASE_API}/api/v1/order/${orderDetail?._id}`,
      { withCredentials: true }
    );
    alert.success("Order Cancelled");
    navigate("/");
  };

  const orderIntractButton = () => {
    if (
      orderDetail?.deliverdAt &&
      new Date().getTime() - new Date(orderDetail?.deliveredAt).getTime() >
        86400000
    ) {
      if (orderDetail?.return?.type === "return") {
        return (
          <>
            <div style={{ width: "100%" }}>
              <div></div>
              <div>
                <span>Waiting for order to be returned</span>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div>
              <div></div>
              <div>
                <span>
                  Exchange/Return window closed on On{" "}
                  {new Date(orderDetail?.deliveredAt).getDate() + 1}{" "}
                  {months[new Date(orderDetail?.deliveredAt).getMonth()]?.slice(
                    0,
                    3
                  )}
                </span>
              </div>
            </div>
          </>
        );
      }
    } else if (
      orderDetail?.user?._id === user?._id &&
      orderDetail?.return?.type === "returned"
    ) {
      if (orderDetail?.deliveredAt) {
        return (
          <>
            <div style={{ width: "700px" }}>
              <div></div>
              <div>
                <span>This order has been returned</span>
              </div>
            </div>
          </>
        );
      }
    } else if (
      orderDetail?.user?._id === user?._id &&
      orderDetail?.return?.type !== "return"
    ) {
      if (orderDetail?.deliveredAt) {
        return (
          <>
            <ReturnButton
              onClick={(e) => {
                exchangeOrder(e);
              }}
            >
              Return
            </ReturnButton>
          </>
        );
      } else {
        return (
          <CancelButton
            onClick={(e: any) => {
              cancelOrder(e);
            }}
          >
            Cancel Order
          </CancelButton>
        );
      }
    }
  };

  const orderStatusText = () => {
    if (orderDetail?.orderStatus === "delivered") {
      return (
        <>
          <div>
            <div>{"Item Delivered"}</div>
          </div>
          <div>
            <div>
              On {days[new Date(orderDetail?.deliveredAt).getDay()]},{" "}
              {new Date(orderDetail?.deliveredAt).getDate()}{" "}
              {months[new Date(orderDetail?.deliveredAt).getMonth()]}{" "}
              {new Date(orderDetail?.deliveredAt)?.getFullYear()}{" "}
            </div>
          </div>
        </>
      );
    } else if (orderDetail?.orderStatus === "Processing order") {
      return (
        <>
          <div>
            <div>{"Delivery Expected by"}</div>
          </div>
          <div>
            <div>
              {" "}
              On{" "}
              {
                days[
                  new Date(new Date().getTime() + 24 * 60 * 60 * 1000).getDay()
                ]
              }
              , {new Date(new Date().getTime() + 24 * 60 * 60 * 1000).getDate()}{" "}
              {
                months[
                  new Date(
                    new Date().getTime() + 24 * 60 * 60 * 1000
                  ).getMonth()
                ]
              }{" "}
              {new Date(
                new Date().getTime() + 24 * 60 * 60 * 1000
              ).getFullYear()}{" "}
            </div>{" "}
          </div>
        </>
      );
    } else if (orderDetail?.orderStatus === "returned") {
      return (
        <>
          <div>
            <div>{"Item Returned"}</div>
          </div>
          <div>
            <div>
              {" "}
              On {days[new Date(orderDetail?.return.createdAt).getDay()]},{" "}
              {new Date(orderDetail?.return.createdAt).getDate()}{" "}
              {months[new Date(orderDetail?.return.createdAt).getMonth()]}{" "}
              {new Date(orderDetail?.return.createdAt).getFullYear()}{" "}
            </div>
          </div>
        </>
      );
    } else if (orderDetail?.orderStatus === "return") {
      return (
        <>
          <div>
            <div>{"Waiting for pickup"}</div>
          </div>

          <div>
            <div> Product will be picked up soon</div>
          </div>
        </>
      );
    }
  };

  if (isLoading) return <Spinner />;
  return (
    <OrderDetails>
      <OrderContainer>
        <OrderPicture>
          <img
            src={orderDetail?.picture}
            alt={orderDetail?.name}
            style={{
              borderRadius: "2px",
              width: "40vh",
              margin: "auto",
            }}
          />
          {isValid()}
          <span> {orderDetail?.name} </span>
          <span>Size:{orderDetail?.size}</span>
        </OrderPicture>

        <DeliveryContainer>{orderStatusText()}</DeliveryContainer>

        <div style={{ width: "100%", background: "white" }}>
          {orderIntractButton()}
        </div>

        <AddressDetail>
          <span style={{ fontSize: "20px", fontWeight: "600" }}>Address</span>
          <span>
            {" "}
            {orderDetail?.user?.name} {orderDetail?.shippingInfo?.number}{" "}
          </span>
          <span>
            {orderDetail?.shippingInfo?.address}

            {orderDetail?.shippingInfo?.landmark}
          </span>
        </AddressDetail>

        <PriceDetailContainer>
          <span style={{ fontSize: "20px" }}>
            Price Detail: You saved ₹
            {(orderDetail?.MRPprice - orderDetail?.price).toLocaleString(
              "en-IN"
            )}{" "}
          </span>
          <ProductPrice>
            <PriceSpan>
              MRP Price{" "}
              <Mrp>₹{orderDetail?.MRPprice.toLocaleString("en-IN")}</Mrp>{" "}
            </PriceSpan>
            <PriceSpan>
              Selling Price{" "}
              <span>₹{orderDetail?.price.toLocaleString("en-IN")}</span>{" "}
            </PriceSpan>
            <PriceSpan>
              Shipping fee <ShippingFee>Free</ShippingFee>
            </PriceSpan>
            <PriceSpan style={{ fontSize: "20px" }}>
              Total Amount
              <span>₹{orderDetail?.price?.toLocaleString("en-IN")}</span>
            </PriceSpan>
          </ProductPrice>
        </PriceDetailContainer>

        <StatusContainer>
          {user?.role === "admin" &&
          orderDetail?.return?.type !== "returned" ? (
            orderDetail?.return?.type === "return" ? (
              <div>
                <span style={{ fontFamily: "inherit", fontSize: "20px" }}>
                  Status
                </span>
                <div>
                  <SelectOption
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="Processing">Processing</option>
                    <option value="Delivered">Returned</option>
                  </SelectOption>
                </div>
                <UpdateButton
                  onClick={() => updateOrderHandler(orderDetail?._id)}
                >
                  Update Status
                </UpdateButton>
              </div>
            ) : (
              <div>
                <h4 style={{ fontFamily: "inherit", fontSize: "20px" }}>
                  Status
                </h4>
                <div>
                  <SelectOption
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="Processing">Processing</option>
                    <option value="Delivered">Delivered</option>
                  </SelectOption>
                </div>
                <UpdateButton
                  onClick={() => updateOrderHandler(orderDetail?._id)}
                >
                  Update Status
                </UpdateButton>
              </div>
            )
          ) : null}
        </StatusContainer>
      </OrderContainer>
    </OrderDetails>
  );
};

export default ProcessOrder;
