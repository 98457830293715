import React, { useState } from "react";
import styled from "styled-components";
import signIn from "./Firebase";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  font-family: "Roboto", "Helvetica", "Arial";
  border: 1px solid #000;
  font-size: large;
  border-radius: 5px;
`;

const Center = styled.div`
  text-align: center;
`;

const Title = styled.h4`
  font-size: large;
  font-weight: 500;

  text-align: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
`;

const TxtField = styled.div`
  width: 500px;
  margin: 20px;

  @media screen and (max-width: 480px) {
    width: 200px;
    margin: 10px;
  }
`;

const Input = styled.input`
  font-size: large;
  outline: none;

  border: none;
  border-bottom: 2px solid #999;
  margin: 10px 0 10px 0;

  padding: 10px 5px;
  width: 90%;

  @media screen and (max-width: 480px) {
    width: 80%;
  }
`;

const LoginButton = styled.input`
  padding: 10px 80px;
  font-size: large;
  border: 2px solid #00a2f3;
  background-color: #2da7e4;
  color: #fff;
  margin: 10px 0;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    background-color: #018dd2;
  }

  @media screen and (max-width: 480px) {
    padding: 10px 40px;
  }
`;

const SignupLink = styled.div`
  margin: 10px 0;
  text-align: center;
`;

const SignupButton = styled.a`
  cursor: pointer;
  color: #2da7e4;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  height: 7rem;
`;

const GoogleButton = styled.button`
  border: 1px solid #999;
  background: #fff;
  border-radius: 10px;
  width: 75%;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #2da7e4;
  cursor: pointer;

  &:hover {
    border: 2px solid #018dd2;
  }

  @media screen and (max-width: 480px) {
    border: none;
  }
`;

const GoogleIcon = styled.img`
  width: 40px;
  font-size: large;
  margin-bottom: 3px;
  margin-right: 5px;
  display: inline-block;
`;
const Span = styled.span`
  font-size: 1rem;

  @media screen and (max-width: 480px) {
    display: none;
  }
`;
const Subtitle = styled.h3`
  font-size: 1rem;
  color: #333;
  text-align: center;
`;
const ChangeNumberLink = styled.a`
  margin-top: 20px;
  cursor: pointer;
`;
const CenterOtp = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px;
`;
const OtpContainer = styled(OtpInput)``;

const LoginForm: any = ({
  SetOtpInfo,
  getCodeFromUserInput,
  onSignInSubmit,
  WhatFor,
  SetWhatFor,
  otpInfo,
}: {
  SetOtpInfo: any;
  getCodeFromUserInput: any;
  onSignInSubmit: any;
  WhatFor: any;
  otpInfo: any;
  SetWhatFor: any;
}) => {
  const [inputName, setInputName] = useState("");
  const [inputNumber, setInputNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [time, setTime] = useState(30);
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();

  const nameHandler = (name: any) => {
    const rawName = name.split(" ");
    return rawName
      .filter((el: any) => !(el === " " || el === ""))
      .map((el: any) => el.charAt(0).toUpperCase() + el.slice(1).toLowerCase())
      .join(" ");
  };

  const handleChange = async (otp: any) => {
    await setOtp(otp);
    SetOtpInfo("");
    if (otp.length === 6) {
      const finalOtp = otp;
      console.log(otp.length, otp, finalOtp);
      getCodeFromUserInput(
        otp,
        nameHandler(inputName),
        `+91${inputNumber}`,
        "phone"
      );
    }
  };

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    console.log("ON FORM SUBMIT", inputNumber.toString().length);

    if (inputNumber.toString().length < 10) {
      return (alert as any).error("Please enter a valid number");
    }

    const name = nameHandler(inputName);
    const method = "phone";
    const phoneNumber = `+91${inputNumber}`;

    setIsDisabled(true);
    onSignInSubmit(e, phoneNumber, name, method);
    navigate("/");
  };
  const render = (WhatFor: any) => {
    if (WhatFor === "login") {
      return (
        <Container>
          <Center>
            <Title>Login</Title>
            <form onSubmit={onFormSubmit}>
              <TxtField>
                {/* <Label>Phone Number</Label> */}
                <Input
                  placeholder="Phone Number..."
                  type="number"
                  required
                  onChange={(e) => {
                    setIsDisabled(false);
                    if (e.target.value.length < 11)
                      setInputNumber(e.target.value);
                  }}
                  onKeyDown={(evt) =>
                    (evt.key === "e" && evt.preventDefault()) ||
                    (evt.key === "." && evt.preventDefault()) ||
                    (evt.key === "-" && evt.preventDefault()) ||
                    (evt.key === "+" && evt.preventDefault())
                  }
                  value={inputNumber}
                />
              </TxtField>
              <LoginButton
                type="submit"
                value="Login"
                className="login-btn"
                disabled={isDisabled}
              />
              <SignupLink>
                Not a user?{" "}
                <SignupButton onClick={() => SetWhatFor("register")}>
                  Signup
                </SignupButton>
              </SignupLink>
            </form>
          </Center>
          <FlexContainer>
            <GoogleButton type="submit" onClick={signIn}>
              <GoogleIcon
                width="20px"
                style={{
                  marginBottom: "3px",
                  marginRight: "5px",
                  display: "inline-block",
                }}
                alt="Google sign-in"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
              />
              <Span> Login with Google</Span>
            </GoogleButton>
          </FlexContainer>
        </Container>
      );
    }
    if (WhatFor === "register") {
      return (
        <Container>
          <Center>
            <Title>Register</Title>
            <form onSubmit={onFormSubmit}>
              <TxtField>
                {/* <Label>Name</Label> */}
                <Input
                  placeholder="Name..."
                  autoFocus
                  type="text"
                  required
                  onChange={(e) => {
                    setIsDisabled(false);
                    setInputName(e.target.value);
                  }}
                  value={inputName}
                />
              </TxtField>

              <TxtField>
                {/* <Label>Phone Number</Label> */}
                <Input
                  placeholder="Phone Number..."
                  type="number"
                  required
                  onKeyDown={(evt) =>
                    (evt.key === "e" && evt.preventDefault()) ||
                    (evt.key === "." && evt.preventDefault()) ||
                    (evt.key === "-" && evt.preventDefault()) ||
                    (evt.key === "+" && evt.preventDefault())
                  }
                  onChange={(e) => {
                    setIsDisabled(false);
                    e.target.value.length < 11
                      ? setInputNumber(e.target.value)
                      : console.log();
                  }}
                  value={inputNumber}
                />
              </TxtField>

              <LoginButton
                type="submit"
                value="Register"
                className="login-btn"
                disabled={isDisabled}
              />

              <SignupLink>
                Already a user?{" "}
                <SignupButton onClick={() => SetWhatFor("login")}>
                  Login
                </SignupButton>
              </SignupLink>
            </form>
          </Center>

          <FlexContainer>
            <GoogleButton type="submit" onClick={signIn}>
              <GoogleIcon
                width="20px"
                style={{
                  marginBottom: "3px",
                  marginRight: "5px",
                  display: "inline-block",
                }}
                alt="Google sign-in"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
              />
              <Span>Register with Google</Span>
            </GoogleButton>
          </FlexContainer>
        </Container>
      );
    }
    if (WhatFor === "OTP") {
      return (
        <Container>
          <Title>Verify with OTP</Title>
          <Subtitle>Sent to {inputNumber}</Subtitle>
          <CenterOtp>
            <OtpContainer
              value={otp}
              onChange={handleChange}
              numInputs={6}
              containerStyle="otpContainer scale-mobile"
              inputStyle={`otpContainer-input${otpInfo} `}
              shouldAutoFocus={true}
              renderSeparator={<span style={{ margin: "0 8px" }}></span>}
              renderInput={(props) => <input {...props} />}
            />
          </CenterOtp>
          <SignupLink>
            <ChangeNumberLink onClick={() => SetWhatFor("login")}>
              Change Number
            </ChangeNumberLink>
          </SignupLink>
        </Container>
      );
    }
  };

  return render(WhatFor);
};

export default LoginForm;
