import { useEffect, useState } from "react";
import styled from "styled-components";

import { useAlert } from "react-alert";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import Spinner from "../../../ui/Spinner";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { getVendorOrder } from "../../action/orderAction";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";

const Edit = styled(Link)`
  padding: 5px;
  border-radius: 5px;
  color: #000888;

  &:hover {
    border: 2px solid #b303ff;
  }

  &:active {
    background-color: #e098ff;
  }
`;

const DivContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20%;
  margin-bottom: 10%;
  font-size: large;
  font-weight: 700;
`;

const VendorOrderList = () => {
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const [rows, setRows] = useState([]);

  const { orders, error, isLoading } = useAppSelector(
    (state: any) => state.vendor
  );
  // console.log(orders, isLoading, orders?.length);
  const columns: GridColDef[] = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: "Image",
      headerName: "Image",
      width: 150,
      editable: false,

      renderCell: (params: any) => (
        <Link to={`/product/${params.value.productId}`}>
          <img
            alt=""
            src={params.value.src}
            style={{
              height: "150px",
              width: "150px",
              objectFit: "contain",
            }}
          />
        </Link>
      ),
    },
    {
      field: "Order",
      headerName: "Name",
      width: 150,
    },
    {
      field: "Status",
      headerName: "Status",
      width: 150,
    },
    {
      field: "Price",
      headerName: "Price",
      type: "number",
      width: 110,
    },
    {
      field: "createdAt",
      headerName: "created at",
      description: "This column has a value getter and is not sortable.",
      width: 160,
    },
    {
      field: "Edit",
      headerName: "Edit",
      width: 150,
      renderCell: (params: any) => (
        <Edit to={`/vendor/order/${params.value}`}>
          <FiEdit size={25} />
        </Edit>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getVendorOrder(false));

    if (error) {
      alert.error(error);
    }
  }, [dispatch, alert, error]);

  useEffect(() => {
    let as: any = [];
    orders?.forEach((order: any, i: any) => {
      as.push({
        id: i,
        Image: { src: order?.picture, productId: order?.product },
        Status: order?.orderStatus,
        Order: order?.name,
        Price: order?.price,
        createdAt: new Date(order?.createdAt)?.toISOString()?.split("T")[0],
        Edit: order?._id,
      });
      // console.log(order?.product);
    });
    setRows(as);
  }, [orders]);

  if (isLoading) return <Spinner />;
  return (
    <div>
      {rows?.length ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <DataGrid
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              backgroundColor: "#f2f2f2",
            }}
            rows={rows}
            columns={columns}
            rowHeight={150}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 50,
                },
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
          />
        </Box>
      ) : (
        <DivContainer>No Order Found</DivContainer>
      )}
    </div>
  );
};
export default VendorOrderList;
