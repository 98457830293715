import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface WishlistState {
  wishlistProduct: [] | null;
  isLoading: boolean;
}

const initialState: WishlistState = {
  wishlistProduct: null,
  isLoading: false,
};

const wishlistSlice: any = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    wishlistLoading: (state) => {
      state.isLoading = !state.isLoading;
    },

    wishlist: (state, action: PayloadAction<any>) => {
      state.wishlistProduct = action.payload;
    },
  },
});

export default wishlistSlice.reducer;
