import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../features/hooks/hooks";
import { getCart, deleteCart } from "../features/action/cartAction";
import { FaTimes, FaTrash } from "react-icons/fa";
import Spinner from "../ui/Spinner";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { BASE_API } from "../utils/baseApi";

const CartPageContainer = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  display: grid;
  margin-left: 60px;
  margin-right: 70px;
  padding: 40px;
  gap: 10px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    padding-left: 5px;
    padding-right: 5px;
  }
  @media screen and (min-width: 768px) and (max-width: 1020px) {
    margin-right: 0;
  }
`;

const CartItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Arial, Helvetica, sans-serif;
  /* margin-right: 10%; */
  padding: 15px 0;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  @media (max-width: 768px) {
    margin-right: 1%;
  }
`;

const Button = styled.button`
  border: none;
  margin: 0 5px;
  background: none;
  cursor: pointer;
`;

const CartItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 5px;
  width: 60%;
  /* justify-content: space-between; */

  @media (max-width: 768px) {
    width: 95%;
  }
`;

const CartItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  width: 100%;
  flex-direction: row;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const DeleteItem = styled.button`
  display: grid;
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  cursor: pointer;
`;

const CartItemImage = styled.img`
  max-width: 150px;
  height: 150px;

  @media (max-width: 480px) {
    width: 100px;
    height: 100px;
  }
`;

const CartItemDetails = styled.div`
  justify-content: space-around;
  display: flex;
  flex-direction: row;
`;

const CartImageDetail = styled.div`
  justify-content: space-around;
  padding: 0 0 0 20px;
  display: flex;
  flex-direction: column;
`;

const CartItemTitle = styled.h3`
  font-size: 15px;
  font-weight: 100;
  margin: 0;
`;

const CartItemSize = styled.p`
  background-color: #e9e9e9f1;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  padding: 2px;
  width: 60px;
  font-weight: 200;
  font-size: 15px;
  margin: 0;
`;

const CartItemPrice = styled.p`
  font-size: 15px;
  margin: 0;
`;

const MoveToWishlist = styled.button`
  display: flex;
  margin-top: 100px;
  align-items: center;
  text-align: center;
  padding: 10px 20px;
  position: relative;
  border: 1px solid gray;
  background-color: #fff;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: lightgray;
  }

  @media (max-width: 480px) {
    padding: 10px 5px;
  }
`;

const PriceDetail = styled.div`
  margin-top: 150px;
  font-size: 15px;
  font-weight: 400;
  margin-left: 60%;
  position: fixed;
  justify-content: space-around;

  @media (max-width: 768px) {
    position: relative;
    display: flex;
    width: 100%;
    margin: 0;
    flex-direction: column;
  }
`;

const PriceContainer = styled.div`
  margin-top: 16px;
  background: #fff;
  /* padding: 16px; */
`;

const PriceHeader = styled.div`
  display: flex;
  padding-left: 8px;
  padding-bottom: 8px;
  font-size: 12px;
  font-weight: 600;
  border-bottom: 1px solid #f5f5f6;
`;
const AllProductPrice = styled.div`
  display: flex;
  padding: 8px;
  justify-content: space-between;
  border-bottom: 2px solid #f5f5f6;
`;

const DeliverPrice = styled.span`
  text-decoration: line-through;
  margin-right: 5px;
`;

const FreeSpan = styled.span`
  color: green;
`;
const FreeDeliveryText = styled.span`
  display: flex;
  padding: 8px 8px;
  background-color: #f2faf9;
`;

const TotalPrice = styled.div`
  display: flex;
  font-size: 17px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  padding: 8px;
  justify-content: space-between;
  border-bottom: 2px solid #f5f5f6;
`;

const PlaceOrderButton = styled.button`
  width: 100%;
  text-align: center;
  padding: 10px;
  float: right;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid #d4d5d9;
  background-color: #ff3f6c;
  color: #fff;
  border-radius: 4px;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    text-decoration: none;
    background-color: #e32753;
  }
`;

const EmptyCartMessage = styled.p`
  font-weight: 700;
  font-size: 18px;
  margin-top: 20px;
`;
const StyledEmpty = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 10px 20px;
  margin-top: 20px;

  background-color: #fff;
  cursor: pointer;
  border-radius: 5px;

  @media screen and (max-width: 480px) {
    margin-top: 90px;
  }
`;

const EmptyCartButton = styled(Link)`
  width: 40%;

  padding: 10px;
  /* margin-top: 50px; */
  text-decoration: none;
  font-size: 14px;
  border: none;
  border: 1px solid #ff3f6c;
  background-color: #fff;
  color: #ff3f6c;
  border-radius: 4px;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    text-decoration: none;
    background-color: #e32753;
    color: #fff;
  }
`;

interface CartProps {
  history: any;
}

const CartPage: React.FC<CartProps> = () => {
  const dispatch = useAppDispatch();
  const cartItems = useAppSelector((state: any) => state.cart.cartproduct);
  const isLoading = useAppSelector((state: any) => state.cart.isLoading);
  const [show, setShow] = useState(false);
  // const [showWish, setShowWish] = useState(false);
  const navigate = useNavigate();

  const isOutOfStock = () => {
    return cartItems?.find((el: any) => el?.product?.inStock === false);
  };

  const toShipping = (e: any) => {
    e.preventDefault();
    if (isOutOfStock()) return alert("A product is out of stock");
    navigate("/shipping");
  };

  useEffect(() => {
    dispatch(getCart());
  }, [dispatch]);

  const removeAllCartItems = async () => {
    await dispatch(deleteCart("DELETEALL"));
    setShow(false);
  };

  const moveAllCartItem = async () => {
    await dispatch(deleteCart("MOVETOWISHLISTALL"));
    await dispatch(deleteCart("DELETEALL"));
    setShow(false);
  };

  const moveToWishlist = async (cartId: any) => {
    await axios.put(
      `${BASE_API}/api/v1/wishlist`,
      {
        productId: cartId,
      },
      { withCredentials: true }
    );
    dispatch(deleteCart(cartId));
  };

  const deleteCartItem = async (cartId: string) => {
    await dispatch(deleteCart(cartId));
  };

  const reducer = (previousValue: any, currentValue: any) => {
    return previousValue + currentValue?.product?.price;
  };
  const mrpReducer = (previousValue: any, currentValue: any) => {
    return (
      previousValue +
      (currentValue?.product?.MRPprice || currentValue?.product?.price)
    );
  };

  if (isLoading) return <Spinner />;
  return (
    <>
      <CartPageContainer>
        <>
          {cartItems && cartItems.length > 0 ? (
            <>
              <div>
                <CartItem>
                  <div>
                    {cartItems?.length} Items ((₹
                    {Math.round(cartItems?.reduce(reducer, 0))?.toLocaleString(
                      "en-IN"
                    )}
                    ))
                  </div>

                  <div>
                    <Button onClick={() => removeAllCartItems()}>
                      <FaTrash size={16} />
                    </Button>
                    <Button onClick={() => moveAllCartItem()}>
                      <svg width="20" height="20" fill="currentColor">
                        <path d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" />
                      </svg>
                    </Button>
                  </div>
                </CartItem>
              </div>

              {cartItems.map((item: any) => (
                <>
                  <CartItemsContainer>
                    <div>
                      <CartItemContainer key={item._id}>
                        <CartItemDetails>
                          <DeleteItem
                            onClick={() => deleteCartItem(item?.product._id)}
                          >
                            <FaTimes size={20} />
                          </DeleteItem>
                          <CartItemImage
                            src={item.product?.images[0].url}
                            alt={item.product?.name}
                          />

                          <CartImageDetail>
                            <CartItemTitle>{item.product?.name}</CartItemTitle>
                            <CartItemSize>Size: {item?.size}</CartItemSize>
                            <CartItemPrice>
                              ₹{item.product?.price?.toFixed(2)}
                            </CartItemPrice>
                          </CartImageDetail>
                        </CartItemDetails>
                        <MoveToWishlist
                          onClick={() => moveToWishlist(item?.product._id)}
                        >
                          Move to wishlist
                        </MoveToWishlist>
                      </CartItemContainer>
                    </div>
                  </CartItemsContainer>
                </>
              ))}

              <PriceDetail>
                <PriceContainer>
                  <PriceHeader>
                    PRICE DETAILS ({cartItems?.length} Items)
                  </PriceHeader>
                  <div>
                    <div>
                      <AllProductPrice>
                        <span>Total MRP</span>
                        <span>
                          <span></span>

                          <span>
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="10"
                              viewBox="0 0 8 10"
                              className="priceDetail-base-icon inline mr-1"
                            >
                              <path
                                fill-rule="nonzero"
                                d="M5.841 9.44l-1.21.513L.365 5.968l.547-.875c.251-.005.529-.033.834-.086a4.91 4.91 0 0 0 .896-.242c.292-.11.556-.251.793-.424s.412-.383.526-.63H.564V2.578h3.431a1.779 1.779 0 0 0-.307-.51 1.99 1.99 0 0 0-.52-.426 2.789 2.789 0 0 0-.738-.29 3.955 3.955 0 0 0-.957-.107h-.91V0h6.31v1.244H4.522c.082.082.162.179.239.29a3.867 3.867 0 0 1 .39.712c.05.12.086.23.109.331h1.613v1.135H5.246a2.51 2.51 0 0 1-.434.906 3.293 3.293 0 0 1-.718.694 3.884 3.884 0 0 1-.916.478 5.378 5.378 0 0 1-1.028.267L5.84 9.44z"
                              ></path>
                            </svg>
                            {Math.round(
                              cartItems?.reduce(mrpReducer, 0)
                            )?.toLocaleString("en-IN")}
                          </span>
                        </span>
                      </AllProductPrice>
                      <AllProductPrice>
                        <span>Discount on MRP</span>

                        {Math.round(
                          cartItems?.reduce(mrpReducer, 0) -
                            cartItems?.reduce(reducer, 0)
                        ) ? (
                          <FreeSpan>
                            <span>
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="8"
                                height="10"
                                viewBox="0 0 8 10"
                                className="priceDetail-base-icon inline mr-1"
                              >
                                <path
                                  fill-rule="nonzero"
                                  d="M5.841 9.44l-1.21.513L.365 5.968l.547-.875c.251-.005.529-.033.834-.086a4.91 4.91 0 0 0 .896-.242c.292-.11.556-.251.793-.424s.412-.383.526-.63H.564V2.578h3.431a1.779 1.779 0 0 0-.307-.51 1.99 1.99 0 0 0-.52-.426 2.789 2.789 0 0 0-.738-.29 3.955 3.955 0 0 0-.957-.107h-.91V0h6.31v1.244H4.522c.082.082.162.179.239.29a3.867 3.867 0 0 1 .39.712c.05.12.086.23.109.331h1.613v1.135H5.246a2.51 2.51 0 0 1-.434.906 3.293 3.293 0 0 1-.718.694 3.884 3.884 0 0 1-.916.478 5.378 5.378 0 0 1-1.028.267L5.84 9.44z"
                                ></path>
                              </svg>
                              {Math.round(
                                cartItems?.reduce(mrpReducer, 0) -
                                  cartItems?.reduce(reducer, 0)
                              )?.toLocaleString("en-IN")}
                            </span>
                          </FreeSpan>
                        ) : (
                          <span>
                            <span>-</span>
                          </span>
                        )}
                      </AllProductPrice>

                      <AllProductPrice>
                        <span>Delivery Charge</span>
                        <span>
                          <span>
                            <DeliverPrice>₹30</DeliverPrice>
                          </span>
                          <FreeSpan>FREE</FreeSpan>
                        </span>
                      </AllProductPrice>
                      <TotalPrice>
                        <span>Total Amount</span>
                        <span>
                          <span></span>

                          <span>
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="10"
                              viewBox="0 0 8 10"
                              className="priceDetail-base-icon inline mr-1"
                            >
                              <path
                                fill-rule="nonzero"
                                d="M5.841 9.44l-1.21.513L.365 5.968l.547-.875c.251-.005.529-.033.834-.086a4.91 4.91 0 0 0 .896-.242c.292-.11.556-.251.793-.424s.412-.383.526-.63H.564V2.578h3.431a1.779 1.779 0 0 0-.307-.51 1.99 1.99 0 0 0-.52-.426 2.789 2.789 0 0 0-.738-.29 3.955 3.955 0 0 0-.957-.107h-.91V0h6.31v1.244H4.522c.082.082.162.179.239.29a3.867 3.867 0 0 1 .39.712c.05.12.086.23.109.331h1.613v1.135H5.246a2.51 2.51 0 0 1-.434.906 3.293 3.293 0 0 1-.718.694 3.884 3.884 0 0 1-.916.478 5.378 5.378 0 0 1-1.028.267L5.84 9.44z"
                              ></path>
                            </svg>
                            {Math.round(
                              cartItems?.reduce(reducer, 0)
                            )?.toLocaleString("en-IN")}
                          </span>
                        </span>
                      </TotalPrice>
                    </div>
                  </div>
                  <FreeDeliveryText>
                    <div>
                      <span>Yay!</span>
                      <FreeSpan>No Delivery Fee </FreeSpan>
                      <DeliverPrice>₹30</DeliverPrice>
                      on this order
                    </div>
                  </FreeDeliveryText>
                  <PlaceOrderButton
                    onClick={(e) => toShipping(e)}
                    // to="/shipping"
                    type="submit"
                  >
                    Place Order
                  </PlaceOrderButton>
                </PriceContainer>
              </PriceDetail>
            </>
          ) : (
            <StyledEmpty>
              <div>
                <div
                  style={{
                    height: "165px",
                    width: "146px",
                    paddingLeft: "60px",
                  }}
                >
                  <img
                    src="https://constant.myntassets.com/checkout/assets/img/empty-bag.png"
                    alt=""
                    style={{ width: "100%" }}
                  />
                </div>
                <EmptyCartMessage>Hey, it feels so light!</EmptyCartMessage>
                <p>There is nothing in your bag. Let's add some items.</p>

                <EmptyCartButton to="/wishlist" data-testid="button">
                  ADD ITEMS FROM WISHLIST
                </EmptyCartButton>
              </div>
            </StyledEmpty>
          )}
        </>
      </CartPageContainer>
    </>
  );
};

export default CartPage;
