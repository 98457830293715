import { useEffect } from "react";
import { useAlert } from "react-alert";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { getVendorProducts } from "../action/productAction";
import { getVendorOrder } from "../action/orderAction";
import Spinner from "../../ui/Spinner";
import { NavLink } from "react-router-dom";
import { HiChevronRight } from "react-icons/hi2";
import styled from "styled-components";

const TotalAmountContainer = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  margin: 10px;
  padding: 20px;
  display: flex;
  width: 95%;
  color: #333;
  border: 2px solid #dbdbdb;
  transition: box-shadow 0.1s linear;
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 12px 2px #dbdbdb;
  border-radius: 10px;
  justify-content: space-between;
  text-decoration: none;

  &:hover {
    z-index: 1;
    box-shadow: 0 1px 12px 2px #dbdbdb;
  }

  @media screen and (max-width: 1020px) {
    width: 85%;
  }
`;

const OverViewContainer = styled(NavLink)`
  font-family: Arial, Helvetica, sans-serif;
  margin: 10px;
  padding: 20px;
  display: flex;
  width: 95%;
  color: #333;
  border: 2px solid #dbdbdb;
  transition: box-shadow 0.1s linear;
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);

  border-radius: 10px;
  /* justify-content: space-between; */
  text-decoration: none;

  &:hover {
    z-index: 1;
    box-shadow: 0 1px 12px 2px #dbdbdb;
    text-decoration: underline;
    color: #003366;
  }

  @media screen and (max-width: 1020px) {
    width: 85%;
  }
`;

const DetailContainer = styled.div`
  display: flex;
  position: absolute;
  left: 90%;
  @media screen and (max-width: 1020px) {
    left: 70%;
  }
`;
const NumberSpan = styled.span`
  display: flex;
  position: absolute;
  left: 50%;
  @media screen and (max-width: 480px) {
    left: 55%;
  }
`;

const VendorDashboard = () => {
  const alert = useAlert();
  const dispatch = useAppDispatch();

  const { products, orders, error, isLoading } = useAppSelector(
    (state: any) => state.vendor
  );

  useEffect(() => {
    dispatch(getVendorProducts());

    dispatch(getVendorOrder(false));

    if (error) {
      alert.error(error);
    }
  }, [dispatch, error, alert]);

  const reducer = (previousValue: any, currentValue: any) => {
    return previousValue + currentValue?.price;
  };

  if (isLoading) return <Spinner />;
  return (
    <>
      <h1>DashBoard</h1>
      <>
        <TotalAmountContainer>
          <div>Total Sales</div>
          <span>
            {Math.round(orders && orders?.reduce(reducer, 0)).toLocaleString(
              "en-IN"
            )}
          </span>
        </TotalAmountContainer>

        <OverViewContainer to="/vendor/products">
          <>
            <div>No. of Products</div>
            <NumberSpan>{products?.length}</NumberSpan>
          </>
          <DetailContainer>
            <span>View Details</span>
            <span>
              <HiChevronRight />
            </span>
          </DetailContainer>
        </OverViewContainer>

        <OverViewContainer to="/vendor/orders">
          <>
            <div>Total Orders</div>
            <NumberSpan>{orders?.length}</NumberSpan>
          </>

          <DetailContainer>
            <span>View Details</span>
            <span>
              <HiChevronRight />
            </span>
          </DetailContainer>
        </OverViewContainer>

        <OverViewContainer to="/vendor/undeliveredorders">
          <>
            <div>Yet to be Delivered</div>
            <NumberSpan>
              {typeof orders === "object"
                ? orders?.filter((el: any) => el.orderStatus !== "delivered")
                    .length
                : null}
            </NumberSpan>
          </>
          <DetailContainer>
            <span>View Details</span>
            <span>
              <HiChevronRight />
            </span>
          </DetailContainer>
        </OverViewContainer>
      </>
    </>
  );
};

export default VendorDashboard;
