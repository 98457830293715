import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MainNav from "./MainNav";

const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100%;
  z-index: 900;

  background-color: #008;

  color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: left 0.3s ease-in-out;
`;

const SidebarTitle = styled.h2`
  font-size: 30px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const SidebarToggleButton = styled.button`
  position: absolute;
  top: 30px;
  left: 10px;
  background-color: transparent;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 24px;
  z-index: 1000;

  @media screen and (max-width: 480px) {
    top: 15px;
    left: 5px;
  }
`;

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (isOpen && event.target.closest("#sidebar-container") === null) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    // console.log("click");
    return () => {
      document.removeEventListener("click", handleOutsideClick);
      // console.log("outside");
    };
  }, [isOpen]);

  return (
    <>
      <div id="sidebar-container">
        <SidebarToggleButton
          onClick={toggleSidebar}
          style={{ color: isOpen ? "#fff" : "#333" }}
        >
          &#9776;
        </SidebarToggleButton>

        <SidebarContainer
          onClick={toggleSidebar}
          style={{ left: isOpen ? "0" : "-300px" }}
        >
          <SidebarTitle>Categories</SidebarTitle>

          <MainNav />
        </SidebarContainer>
      </div>
    </>
  );
};

export default Sidebar;
