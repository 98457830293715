import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const LogoContainer = styled(NavLink)`
  font-size: 24px;
  font-weight: bold;
  margin-left: -10%;
  cursor: pointer;
  text-decoration: none;
  color: #333;
  background-color: transparent;

  @media screen and (max-width: 768px) {
    margin-left: -5%;
  }
  @media screen and (max-width: 480px) {
    margin-left: -15%;
  }
`;

const Logo: React.FC = () => {
  return (
    <LogoContainer to="/">
      {" "}
      <span style={{ fontSize: "larger" }}>G</span>cloth
    </LogoContainer>
  );
};

export default Logo;
