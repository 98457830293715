import axios from "axios";
import { BASE_API } from "../../utils/baseApi";

export function getCart() {
  return async function (dispatch: any) {
    try {
      dispatch({ type: "cart/cartLoading" });
      const apiUrl = `${BASE_API}/api/v1/cart`;
      const response = await axios.get(apiUrl, {
        withCredentials: true,
      });
      // console.log(response?.data);
      const cartProducts = response?.data?.cart;

      dispatch({ type: "cart/cartList", payload: cartProducts });
      dispatch({ type: "cart/cartLoading" });
    } catch (error: any) {
      dispatch({ type: "cart/cartLoading" });
      dispatch({ type: "cart/cartError", payload: error?.message });
      console.error("Error fetching data:", error);
    }
  };
}

export function updateCart(size: number, product: string) {
  return async function (dispatch: any) {
    try {
      dispatch({ type: "cart/cartLoading" });
      const apiUrl = `${BASE_API}/api/v1/cart`;
      const response = await axios.put(
        apiUrl,
        {
          data: {
            productId: product,
            size: size,
          },
        },
        {
          withCredentials: true,
        }
      );
      const cartProducts = response?.data?.cart;

      dispatch({ type: "cart/cartList", payload: cartProducts });
      dispatch({ type: "cart/cartLoading" });
    } catch (error: any) {
      dispatch({ type: "cart/cartLoading" });
      dispatch({ type: "cart/cartError", payload: error?.message });
      console.error("Error fetching data:", error);
    }
  };
}

export const deleteCart = (product: string) => async (dispatch: any) => {
  try {
    dispatch({ type: "cart/cartLoading" });
    const apiUrl = `${BASE_API}/api/v1/cart`;
    const response = await axios.delete(apiUrl, {
      headers: {},
      data: {
        productId: product,
      },
      withCredentials: true,
    });
    // console.log(response);
    const cartProducts = response?.data?.cart;

    dispatch({ type: "cart/cartList", payload: cartProducts });
    dispatch({ type: "cart/cartLoading" });
  } catch (error: any) {
    dispatch({ type: "cart/cartLoading" });
    dispatch({ type: "cart/cartError", payload: error?.message });
    console.error("Unable to delete", error);
  }
};
