import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../features/hooks/hooks";

import { getWishList } from "../features/action/wishlistAction";
import Spinner from "../ui/Spinner";
import styled from "styled-components";
import ProductCard from "../features/product/ProductCard";
import { Link } from "react-router-dom";

const WishListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 100%;
`;
const StyledEmpty = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 10px 20px;
  margin-top: 40px;

  background-color: #fff;
  cursor: pointer;
  border-radius: 5px;

  @media screen and (max-width: 480px) {
    margin-top: 90px;
  }
`;

const EmptyCartMessage = styled.p`
  font-weight: 700;
  font-size: 18px;
  margin-top: 20px;
`;

const EmptyCartButton = styled(Link)`
  width: 40%;

  padding: 10px;
  /* margin-top: 50px; */
  text-decoration: none;
  font-size: 14px;
  border: none;
  border: 1px solid #ff3f6c;
  background-color: #fff;
  color: #ff3f6c;
  border-radius: 4px;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    text-decoration: none;
    background-color: #ff3f6c;
    color: #fff;
  }
`;

interface Product {
  _id: string;
  name: string;
  images: { url: string }[];
  price: number;
  MRPprice?: number;
  seller: string;
  isWishlisted: boolean;
}

const Wishlist = () => {
  const dispatch = useAppDispatch();

  let { wishlistProduct: wishList, isLoading } = useAppSelector(
    (state: any) => state.wishList
  );
  const [wishListProduct, setWishListProduct] = useState<Product[] | null>(
    null
  );

  useEffect(() => {
    if (wishListProduct?.length && wishList.length < 1)
      return setWishListProduct([]);
    if (wishList && wishList.length < 1) return;
    if (!(wishList && wishList.length)) dispatch(getWishList());
    let wishProduct = wishList?.map((el: any) =>
      wishList.includes(el)
        ? {
            ...el,
            isWishlisted: "true",
          }
        : "Add something..."
    );

    setWishListProduct(wishProduct);
  }, [dispatch, wishList]);

  if (isLoading) return <Spinner />;

  return (
    <WishListContainer>
      {wishListProduct && wishListProduct.length > 0 ? (
        wishListProduct.map((product: any) => (
          <ProductCard product={product} key={product._id} />
        ))
      ) : (
        <StyledEmpty>
          <div>
            <div
              style={{ height: "165px", width: "146px", paddingLeft: "50px" }}
            >
              <img
                src="https://constant.myntassets.com/checkout/assets/img/empty-bag.png"
                alt=""
                style={{ width: "100%" }}
              />
            </div>
            <EmptyCartMessage>Hey, it feels so light!</EmptyCartMessage>
            <p>There is nothing in your bag. Let's add some items.</p>

            <EmptyCartButton to="/" data-testid="button">
              CONTINUE SHOPPING
            </EmptyCartButton>
          </div>
        </StyledEmpty>
      )}
    </WishListContainer>
  );
};

export default Wishlist;
