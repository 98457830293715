import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import {
  addWishlist,
  deleteWishlist,
  getWishList,
} from "../action/wishlistAction";
import { useAlert } from "react-alert";

const ProductCardContainer = styled.div`
  border: 1px solid #ddd;
  /* padding: 20px; */
  position: relative;
  margin: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  box-shadow: 2px 5px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 250px;
  text-decoration: none;
  transform: scale(1);
  transition: transform 0.9s ease-in-out;

  &:hover {
    transform: translate(1%, 1%) scale(1.05);
    transition: transform 0.9s ease-in-out;

    cursor: pointer;
    box-shadow: 0 1px 12px 2px #dbdbdb;
  }

  &:active {
    color: black;
  }
`;

const ProductName = styled.h3`
  font-size: 18px;
  margin: 2px 0;
  color: #666;
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 2px 0;
`;

const Price = styled.span`
  font-size: 16px;
  color: #333;
`;

const DiscountedPrice = styled.span`
  font-size: 16px;
  color: #e74c3c;
  margin-left: 10px;
  text-decoration: line-through;
`;

const SellerName = styled.p`
  font-size: 14px;
  color: #888;
  margin: 2px;
`;

const StyledImag = styled.img`
  margin-top: 0;
  height: 230px;
  object-fit: contain;

  width: 70%;
`;

interface Product {
  _id: string;
  name: string;
  images: { url: string }[];
  price: number;
  MRPprice?: number;
  seller: string;
  isWishlisted: any;
}

interface ProductCardProps {
  product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const dispatch = useAppDispatch();
  const alert = useAlert();

  const { isAuthenticated } = useAppSelector((state: any) => state.loginuser);

  async function handleDeleteLike(product_id: any) {
    dispatch(deleteWishlist(product_id));
  }
  async function handleAddLike(product_id: any) {
    if (isAuthenticated) {
      await dispatch(addWishlist(product_id));
      dispatch(getWishList());
    } else {
      alert.show("User must be logged in to wishlist an item", {
        offset: "100px",
        position: "bottom center",
        timeout: 25000,
        containerStyle: {
          zIndex: 999,
        },
      });
    }
  }

  return (
    <ProductCardContainer as={Link} to={`/product/${product._id}`}>
      {product && product?.isWishlisted === "true" ? (
        <svg
          fill="red"
          style={{
            position: "absolute",
            left: "0",
            width: "20px",
            height: "20px",
          }}
          onClick={(event) => {
            event.preventDefault();
            handleDeleteLike(product._id);
          }}
        >
          <path d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" />
        </svg>
      ) : (
        <svg
          fill="black"
          style={{
            position: "absolute",
            left: "0",
            width: "20px",
            height: "20px",
          }}
          onClick={(event) => {
            event.preventDefault();
            handleAddLike(product._id);
          }}
        >
          <path d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" />
        </svg>
      )}

      <StyledImag src={product.images[0].url} alt={product.name} />
      <ProductName>{product.name}</ProductName>
      <PriceContainer>
        <Price>₹{product.price}</Price>
        {product.MRPprice && (
          <DiscountedPrice>₹{product.MRPprice}</DiscountedPrice>
        )}
      </PriceContainer>
      <SellerName>Seller: {product.seller}</SellerName>
    </ProductCardContainer>
  );
};

export default ProductCard;
