import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface CartState {
  cartproduct: [] | null;
  isLoading: boolean;
  error: any;
}

const initialState: CartState = {
  cartproduct: null,
  isLoading: false,
  error: "",
};

const cartSlice: any = createSlice({
  name: "cart",
  initialState,
  reducers: {
    cartLoading: (state) => {
      state.isLoading = !state.isLoading;
    },

    cartList: (state, action: PayloadAction<any>) => {
      state.cartproduct = action.payload;
    },
    cartError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
  },
});

export default cartSlice.reducer;
