import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface LoginState {
  isAuthenticated: boolean;
  isLoading: boolean;
  user: any;
  loadedUser: any;
  isLoadedUserLoading: boolean;
}

const initialState: LoginState = {
  isAuthenticated: false,
  isLoading: true,
  user: null,
  loadedUser: null,
  isLoadedUserLoading: false,
};

const loginSlice: any = createSlice({
  name: "login",
  initialState,
  reducers: {
    usersLoading: (state, action: PayloadAction<any>) => {
      state.isLoading = action.payload;
    },
    loginSuccess: (state, action: PayloadAction<any>) => {
      state.user = action.payload.user;
    },
    authentication: (state, action: PayloadAction<any>) => {
      state.isAuthenticated = action.payload;
    },
    loadUser: (state, action) => {
      state.user = action.payload;
    },
  },

  // extraReducers: (builder) => {
  //   builder.addCase(loadUser.ful, (state) => {
  //     state.user = action.payload;
  //   });
  // },
});

export default loginSlice.reducer;
