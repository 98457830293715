import styled from "styled-components";
import { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { useAppDispatch, useAppSelector } from "../features/hooks/hooks";
import {
  getShippingInfo,
  saveShippingInfo,
} from "../features/action/shippingAction";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const ShippingInfoContainer = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  font-weight: 700;

  @media screen and (max-width: 1020px) {
    /* margin: 10px 0; */
  }
`;

const ShippingInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const ShippingH1 = styled.h1`
  align-items: center;
  margin-bottom: 2rem;
`;

const ShippingForm = styled.form`
  padding: 2rem;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 60px -4px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
`;

const FormDiv = styled.div`
  display: grid;
  gap: 10px;
  margin: 20px 0px;
`;

const FormTextarea = styled.textarea`
  border: 2px solid lightgrey;
  background-color: #fff;
  border-radius: 5px;
  font-family: inherit;
  min-height: 1rem;
  max-height: 5rem;
  width: 99%;
  overflow: scroll;
  font-size: medium;
  outline: none;

  &:hover {
    border: 2px solid #1a83ff;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;
const FormInput = styled.input`
  background-color: #fff;
  border: 2px solid lightgrey;
  border-radius: 5px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  max-width: 100%;
  height: 30px;
  outline: none;

  &:hover {
    border: 2px solid #1a83ff;
    box-shadow: 10px 5px 10px -3px rgba(0, 0, 0, 0.1);
  }
`;
const ShippingButton = styled.button`
  background-color: #ff3f6c;
  color: #fff;
  border: 2px solid #e94b70;
  width: 100%;
  cursor: pointer;

  border-radius: 5px;
  max-width: 100%;
  height: 50px;

  &:hover {
    background-color: #e32753;
  }
`;

const Shipping = () => {
  const userAddress = useAppSelector(
    (state: any) => state.shipping.shippingInfo
  );

  const dispatch = useAppDispatch();
  const alert = useAlert();

  const [address, setAddress] = useState("");
  const [landmark, setLandmark] = useState("");
  const [number, setNumber] = useState("");

  const location = useLocation();
  let params = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const ProductId = useParams();

  useEffect(() => {
    const shippingInfo = async () => {
      await dispatch(getShippingInfo());
    };
    shippingInfo();
  }, [dispatch]);

  // console.log(userAddress);
  useEffect(() => {
    setAddress(userAddress?.address || "");
    setLandmark(userAddress?.landmark || "");
    setNumber(userAddress?.number || "");
  }, [userAddress]);

  const submitHandler = async (e: any) => {
    e.preventDefault();
    // console.log(address.trim().length > 5);
    if (address?.trim().length < 5) {
      e.preventDefault();
      return alert.error("Please enter a valid address");
    }
    if (landmark?.trim().length < 5) {
      e.preventDefault();
      return alert.error("Please enter a valid landmark");
    }

    // console.log(number.toString().length, address, landmark);
    if (number.toString().length !== 10) {
      e.preventDefault();
      return alert.error("Please enter a valid number");
    }

    await dispatch(saveShippingInfo({ address, number, landmark }));
    // console.log(ProductId);
    if (ProductId?.productId) {
      e.preventDefault();
      return navigate(
        `/confirmorder/${ProductId.productId}?EzZTAz=${params.get("EzZTAz")}`
      );
    } else {
      e.preventDefault();
      return navigate(`/confirmorder/`);
    }
  };

  return (
    <ShippingInfoContainer>
      <ShippingInfo>
        <ShippingForm>
          <ShippingH1>Shipping Info</ShippingH1>
          <FormDiv>
            <label>Address</label>
            <FormTextarea
              value={address}
              required
              onChange={(e) =>
                e.target.value.length < 150
                  ? setAddress(e.target.value)
                  : alert.show("To long")
              }
            />
          </FormDiv>
          <FormDiv>
            <label>Landmark/Road</label>
            <FormInput
              type="text"
              required
              value={landmark}
              onChange={(e) =>
                e.target.value.length < 40
                  ? setLandmark(e.target.value)
                  : alert.show("To long")
              }
            />
          </FormDiv>
          <FormDiv>
            <label>Phone No</label>
            <FormInput
              required
              value={number}
              onChange={(e) => {
                if (e.target.value.length < 11) setNumber(e.target.value);
              }}
              onKeyDown={(evt) =>
                (evt.key === "e" && evt.preventDefault()) ||
                (evt.key === "." && evt.preventDefault()) ||
                (evt.key === "-" && evt.preventDefault()) ||
                (evt.key === "+" && evt.preventDefault())
              }
            />
          </FormDiv>
          <FormDiv>
            <label>City</label>
            <FormInput type="text" readOnly value="Pichhore" />
          </FormDiv>
          <FormDiv>
            <label>Postal Code</label>
            <FormInput type="number" value="473995" required />
          </FormDiv>

          <ShippingButton onClick={(e) => submitHandler(e)}>
            CONTINUE
          </ShippingButton>
        </ShippingForm>
      </ShippingInfo>
    </ShippingInfoContainer>
  );
};

export default Shipping;
