import { useState, useEffect } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { useAlert } from "react-alert";
import { newProducts } from "../action/productAction";
import { useNavigate } from "react-router-dom";
import Spinner from "../../ui/Spinner";

import { Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";

const CreateProductContainer = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  font-weight: 700;
`;

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;

  @media screen and (max-width: 768px) {
    width: 80%;
  }
`;

const CreateProductForm = styled.form`
  padding: 2rem;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 60px -4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

const Heading1 = styled.h1`
  align-items: center;
  margin-bottom: 2rem;
`;

const FormDiv = styled.div`
  display: grid;
  gap: 10px;
  margin: 20px 0px;
`;

const ImageInput = styled.input.attrs({ type: "file" })`
  &::file-selector-button {
    font: inherit;
    outline: none;
    padding: 0.8rem 1.2rem;
    margin-right: 1.2rem;
    border-radius: 5px;
    border: none;
    color: #fff;
    background-color: #b303ff;
    cursor: pointer;
    transition: color 0.2s, background-color 0.2s;

    &:hover {
      background-color: #7b00b0;
    }

    @media screen and (max-width: 480px) {
      margin-right: 0.2rem;
    }
  }
`;

const FormInput = styled.input`
  background-color: #fff;
  outline: none;
  border: 2px solid lightgrey;
  border-radius: 5px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  max-width: 100%;
  height: 30px;
  transition: border-color 0.3s, box-shadow 0.3s;

  &:hover {
    border-color: #2691d9;
  }

  &:active,
  &:focus {
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 60px -4px rgba(0, 0, 0, 0.1);

    border-color: #2691d9;
  }
`;
const FormTextarea = styled.textarea`
  border: 2px solid lightgrey;
  background-color: #fff;
  outline: none;
  border-radius: 5px;
  font-family: inherit;
  min-height: 1rem;
  max-height: 5rem;
  width: 99%;
  overflow: scroll;
  font-size: medium;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  transition: border-color 0.3s, box-shadow 0.3s;

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    border: 2px solid #2691d9 !important;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  gap: 10px;
  margin: 10px 0px;
`;
const StyledSize = styled.div`
  position: relative;
  right: 5px;
`;
const ImageFile = styled.div``;

const StyledCategorySelect = styled.select`
  padding: 15px 0;
  outline: none;
  border: 2px solid lightgrey;
  border-radius: 5px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  max-width: 100%;

  &:hover {
    border-color: #2691d9;
  }

  &:active,
  &:focus {
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 60px -4px rgba(0, 0, 0, 0.1);

    border-color: #2691d9;
  }
`;

const CreateButton = styled.button`
  background-color: #ff3f6c;
  color: #fff;
  border: 2px solid #e94b70;
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  max-width: 100%;
  height: 50px;

  &:hover {
    background-color: #e32753;
  }
`;

const NewProduct = () => {
  const [name, setName] = useState("");
  const [MRPprice, setMRPPrice] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState<any>("");

  const [imagesPreview, setImagesPreview] = useState<any>([]);
  const [price, setPrice] = useState("");

  const [clothSize, setClothSize] = useState<any>([]);
  const dispatch = useAppDispatch();

  const alert = useAlert();
  const navigate = useNavigate();

  const { error, isLoading, success } = useAppSelector(
    (state: any) => state.newProducts
  );
  // console.log(success);

  useEffect(() => {
    if (error) {
      alert.error(error);
    }

    if (success) {
      navigate("/vendor/products");
      alert.success("Product created successfully");
    }
  }, [dispatch, success, error, navigate, alert]);

  const categories = [
    "Jeans",
    "Trousers",
    "T-shirts",
    "Shirts",
    "Sweatshirts",
    "Shorts",
    "Jackets",
    "Joggers",
    "Briefs",
    "Thermals",
  ];

  const onChanges = (e: any) => {
    const files = Array.from(e.target.files);

    setImagesPreview([]);
    setImages([]);

    files.forEach((file: any) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader?.readyState === 2) {
          setImagesPreview((oldArray: any) => [...oldArray, reader.result]);
          setImages((oldArray: any) => [...oldArray, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const submitHandler = (e: any) => {
    e.preventDefault();

    if (!Array.isArray(clothSize) || clothSize?.length < 1) {
      e.preventDefault();
      return alert.error("Please Select a size");
    }
    if (name?.trim().length < 5) {
      e.preventDefault();
      return alert.error("Please enter a valid Name");
    }
    if (category.trim().length < 2) {
      e.preventDefault();
      return alert.error("Please enter a Category");
    }
    if (price.toString().length < 2) {
      e.preventDefault();
      return alert.error("Please enter a valid Price");
    }

    if (Number(MRPprice) < Number(price)) {
      e.preventDefault();
      return alert.error("Original price cannot be lower than Discount Price");
    }

    if (images.length < 3) {
      e.preventDefault();
      return alert.error("Please add atleast 3 images");
    }
    if (images.length > 10) {
      e.preventDefault();
      return alert.error("Cannot add more than 10 images");
    }
    const newProduct = {
      name,
      MRPprice,
      price,
      description,
      category,
      size: clothSize,
      images,
    };

    dispatch(newProducts(newProduct));
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const sizes = [
    "S",
    "M",
    "L",
    "XL",
    "XXL",
    "XXXL",
    "26",
    "28",
    "30",
    "32",
    "34",
    "36",
    "38",
    "40",
    "42",
    "44",
    "46",
    "48",
  ];

  function getStyles(size: string, clothSize: readonly string[], theme: Theme) {
    return {
      fontWeight:
        clothSize.indexOf(size) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  function MultipleSelectChip() {
    const theme = useTheme();
    // const [clothSize, setClothSize] = React.useState<string[]>([]);

    const handleChange = (event: SelectChangeEvent<typeof clothSize>) => {
      const {
        target: { value },
      } = event;
      setClothSize(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    };

    return (
      <div>
        <FormControl sx={{ m: 1, width: "100%" }}>
          <InputLabel id="demo-multiple-chip-label">Sizes</InputLabel>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={clothSize}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value: any) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {sizes.map((size) => (
              <MenuItem
                key={size}
                value={size}
                style={getStyles(size, clothSize, theme)}
              >
                {size}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }

  if (isLoading) return <Spinner />;
  return (
    <CreateProductContainer>
      <ProductInfo>
        <CreateProductForm onSubmit={submitHandler}>
          <Heading1>New Product</Heading1>

          <FormDiv>
            <label>Name</label>
            <FormInput
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormDiv>

          <FormDiv>
            <label>Discount Price</label>
            <FormInput
              type="number"
              required
              onWheel={(e) => {
                e.stopPropagation();
              }}
              value={price}
              // onKeyUp={(e) => {
              //   e.stopPropagation();
              //   e.preventDefault();
              //   setPrice(e.target.value);
              // }}
              onChange={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setPrice(e.target.value);
              }}
              onKeyDown={(evt: any) => {
                (evt.key === "e" && evt.preventDefault()) ||
                  (evt.key === "." && evt.preventDefault()) ||
                  (evt.key === "-" && evt.preventDefault()) ||
                  (evt.key === "+" && evt.preventDefault());
                setPrice(evt.target.value);
              }}
            />
          </FormDiv>

          <FormDiv>
            <label>Original Price</label>
            <FormInput
              value={MRPprice}
              onChange={(e) => setMRPPrice(e.target.value)}
            />
          </FormDiv>
          <FormDiv>
            <label>Description</label>
            <FormTextarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              onKeyDown={(evt: any) =>
                (evt.key === "e" && evt.preventDefault()) ||
                (evt.key === "." && evt.preventDefault()) ||
                (evt.key === "-" && evt.preventDefault()) ||
                (evt.key === "+" && evt.preventDefault())
              }
            />
          </FormDiv>
          <FormDiv>
            <label>Category</label>
            <StyledCategorySelect
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="" disabled selected>
                Select your option
              </option>
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </StyledCategorySelect>
          </FormDiv>
          <FormDiv>
            <label>Sizes</label>
            <StyledSize>{MultipleSelectChip()}</StyledSize>
          </FormDiv>

          <FormDiv>
            <label>Images</label>
            <ImageFile>
              <ImageInput type="file" onChange={(e) => onChanges(e)} multiple />
            </ImageFile>
          </FormDiv>
          <ImageContainer>
            {imagesPreview?.map((img: any) => (
              <img
                src={img}
                key={img}
                alt="Images Preview"
                width="55px"
                height="52px"
              />
            ))}
          </ImageContainer>
          <CreateButton type="submit" disabled={isLoading ? true : false}>
            CREATE
          </CreateButton>
        </CreateProductForm>
      </ProductInfo>
    </CreateProductContainer>
  );
};
export default NewProduct;
