import Spinner from "../../ui/Spinner";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../hooks/hooks";

import { useAlert } from "react-alert";

interface ProtectedProps {
  children: any;
  isVendor?: boolean;
  isAdmin?: boolean;
  isVendorOrAdmin?: boolean;
}

const ProtectedRoutes: React.FC<ProtectedProps> = ({
  children,
  isVendorOrAdmin,
  isVendor,
  isAdmin,
}) => {
  const navigate = useNavigate();
  const alert = useAlert();

  const { isAuthenticated, isLoading, user } = useAppSelector(
    (state: any) => state.loginuser
  );

  useEffect(
    function () {
      // if (isLoading) return;
      if (!isAuthenticated && !isLoading) navigate("/login");
      if (
        isVendorOrAdmin === true &&
        user?.role !== "vendor" &&
        user?.role !== "admin"
      ) {
        alert.error("Only a vendor or admin can access this route");
        navigate("/");
      }
      if (isVendor === true && user?.role !== "vendor") {
        alert.error("Only a vendor can access this route");
        navigate("/");
      }
      if (isAdmin === true && user?.role !== "admin") {
        alert.error("Only a Admin can accesss this route");
        navigate("/");
      }
    },
    [isAuthenticated, isLoading, navigate, user]
  );

  if (isLoading)
    return (
      <div>
        <Spinner />
      </div>
    );

  if (isAuthenticated) return children;
};

export default ProtectedRoutes;
