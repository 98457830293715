import styled from "styled-components";
import Header from "./Header";
// import ProductList from "../features/product/ProductList";

import Footer from "./Footer";
import { Outlet } from "react-router-dom";

const StyledAppLayout = styled.div`
  /* background-color: #0089; */
`;

const AppLayout: React.FC = () => {
  return (
    <StyledAppLayout>
      <Header />
      <Outlet />
      <Footer />
    </StyledAppLayout>
  );
};

export default AppLayout;
