import React, { useState } from "react";
import { FaUser, FaShoppingCart, FaSearch, FaArrowLeft } from "react-icons/fa";
import { logoutUser } from "../features/action/loginAction";
import SearchBar from "./SearchBar";
import Sidebar from "./Sidebar";
import styled from "styled-components";
import Logo from "./Logo";
import { NavLink } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../features/auth/Firebase";
import { useAppDispatch, useAppSelector } from "../features/hooks/hooks";
import { Link } from "react-router-dom";
import LoginModal from "../features/auth/LoginModal";

const HeaderContainer = styled.header`
  position: static;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-top: 3px solid #444;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 480px) {
    padding: 10px;
  }
`;

const StyledNavLink = styled(NavLink)`
  display: flex;

  color: black;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;

  a:visited {
    text-decoration: none;
    color: black;
  }
  &:hover {
    color: #072bca !important;
  }
`;

const SearchButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.2s ease-in-out;
  position: relative;
  left: 15%;

  &:hover {
    background-color: #eee;
  }
  @media (min-width: 481px) {
    display: none;
  }
`;

const MobileSearchContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 9999;
`;

const MobileBackButton = styled.button`
  position: absolute;
  top: 10px;
  left: 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  padding: 5px;
  border-radius: 5px;
  transition: background-color 0.2s ease-in-out;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50%;

  @media (max-width: 480px) {
    position: relative;
    left: 10px;
    gap: 40%;
  }
`;

const StyledButton = styled.div`
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
  color: black;

  display: grid;
  border: none;
`;

const UserButton = styled.button`
  display: grid;
  grid-template-rows: 1.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  color: #333;

  &:hover {
    color: #072bca;

    background-color: transparent;
  }

  &:active {
    background-color: transparent;
  }
`;
const Div = styled.div`
  display: grid;
  grid-template-rows: 1.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  color: #333;

  &:hover {
    color: #072bca;

    background-color: transparent;
  }

  &:active {
    background-color: transparent;
  }
`;
const UserButtonToggle = styled.button`
  display: grid;
  position: relative;
  grid-template-rows: 1.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  color: #fff;

  &:hover {
    color: #072bca;
    background-color: transparent;
  }

  &:active {
    background-color: transparent;
  }
`;

const StyledToggle = styled.div`
  display: grid;
  position: absolute;
  right: 5%;
  top: 98%;
  background-color: #f9f9f9;
  width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;

  & a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
  }
  & a:hover {
    background-color: #f1f1f1;
  }

  @media screen and (max-width: 480px) {
    position: absolute;
    right: 5%;
    top: 90%;
    width: 100px;
  }
`;

const Header = () => {
  const [showMobileSearch, setShowMobileSearch] = useState("false");
  const { user, isLoading, isAuthenticated } = useAppSelector(
    (state: any) => state.loginuser
  );
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useAppDispatch();
  // const [show, setShow] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const logout = (e: any) => {
    e.preventDefault();
    signOut(auth)
      .then(async () => {
        await dispatch(logoutUser());
        window.location.reload();
      })
      .catch((error) => {});
  };
  const handleSearchIconClick = () => {
    setShowMobileSearch("true");
  };

  const closeMobileSearch = () => {
    setShowMobileSearch("false");
  };

  return (
    <HeaderContainer>
      <LoginModal
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
      />
      <Sidebar />
      <Logo />
      <SearchBar setIsOpen={closeMobileSearch} isopen={showMobileSearch} />

      <SearchButton onClick={handleSearchIconClick}>
        <FaSearch size={18} />
      </SearchButton>

      {showMobileSearch === "true" && (
        <MobileSearchContainer>
          <MobileBackButton onClick={closeMobileSearch}>
            <FaArrowLeft size={16} />
          </MobileBackButton>
          <SearchBar setIsOpen={closeMobileSearch} isopen={showMobileSearch} />
        </MobileSearchContainer>
      )}
      <IconContainer>
        <StyledNavLink to="/cart">
          <UserButton>
            <Div>
              <FaShoppingCart size={20} />
            </Div>
            <StyledButton>Cart</StyledButton>
          </UserButton>
        </StyledNavLink>

        {isAuthenticated ? (
          <>
            {user ? (
              <UserButtonToggle
                onClick={() => setDropdown(dropdown === false ? true : false)}
                onMouseEnter={() => setDropdown(true)}
                onMouseLeave={() => setDropdown(false)}
              >
                <Div>
                  <FaUser size={20} />
                </Div>

                {dropdown ? (
                  <StyledToggle>
                    <Link to="/">Home</Link>
                    <Link to="/wishlist">Wishlist</Link>
                    <Link to="/orders">Orders</Link>
                    {user?.role === "admin" ? (
                      <Link to="/admin/dashboard">Dashboard</Link>
                    ) : null}
                    {user?.role === "vendor" ? (
                      <Link to="/dashboard">Dashboard</Link>
                    ) : null}

                    <Link to="/logout" onClick={(e) => logout(e)}>
                      Logout
                    </Link>
                  </StyledToggle>
                ) : (
                  ""
                )}

                <StyledButton>
                  {!isLoading && user?.name?.split(" ")[0]}
                </StyledButton>
              </UserButtonToggle>
            ) : (
              ""
            )}
          </>
        ) : (
          <UserButton disabled={isLoading} onClick={() => handleClickOpen()}>
            <div>
              <FaUser size={20} />
            </div>

            <StyledButton>Login</StyledButton>
          </UserButton>
        )}
      </IconContainer>
    </HeaderContainer>
  );
};

export default Header;
