import { useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";

import { getVendorProducts } from "../action/productAction";
import { useAlert } from "react-alert";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Spinner from "../../ui/Spinner";
import { Link } from "react-router-dom";

import { FiEdit } from "react-icons/fi";

const Edit = styled(Link)`
  padding: 5px;
  border-radius: 5px;
  color: #000888;

  &:hover {
    border: 2px solid #b303ff;
  }

  &:active {
    background-color: #e098ff;
  }
`;

const Div = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20%;
  margin-bottom: 10%;
  font-size: large;
  font-weight: 700;
`;

const VendorProductList = () => {
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const [rows, setRows] = useState([]);

  const { products, isLoading, error } = useAppSelector(
    (state: any) => state.vendor
  );
  console.log(products);

  useEffect(() => {
    dispatch(getVendorProducts());

    if (error) {
      alert.error(error);
    }
  }, [dispatch, alert, error]);

  const columns: GridColDef[] = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: "Image",
      headerName: "Image",
      width: 150,
      editable: false,

      renderCell: (params: any) => (
        <Link to={`/product/${params.value.productId}`}>
          <img
            alt=""
            src={params.value.src}
            style={{
              height: "150px",
              width: "150px",
              objectFit: "contain",
            }}
          />
        </Link>
      ),
    },
    {
      field: "Product",
      headerName: "Name",
      width: 150,
    },
    {
      field: "Price",
      headerName: "Price",
      type: "number",
      width: 110,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "created at",
      description: "This column has a value getter and is not sortable.",
      width: 160,
    },
    {
      field: "Edit",
      headerName: "Edit",
      width: 150,
      renderCell: (params: any) => (
        <Edit to={`/vendor/updateproduct/${params.value}`}>
          <FiEdit size={25} />
        </Edit>
      ),
    },
  ];

  useEffect(() => {
    let as: any = [];
    products?.forEach((product: any, i: any) => {
      as.push({
        id: i,
        Product: product?.name,

        Image: { src: product?.images[0]?.url, productId: product?._id },
        Price: product?.price,
        createdAt: new Date(product?.createdAt).toISOString().split("T")[0],
        Edit: product?._id,
      });
    });
    setRows(as);
  }, [products]);

  if (isLoading) return <Spinner />;
  return (
    <div>
      {rows?.length ? (
        <DataGrid
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            backgroundColor: "#f2f2f2",
          }}
          rows={rows}
          columns={columns}
          rowHeight={150}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 50,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
        />
      ) : (
        <Div>No Product Found</Div>
      )}
    </div>
  );
};

export default VendorProductList;
