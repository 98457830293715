import { useEffect, useState } from "react";
import styled from "styled-components";
import { FiEdit } from "react-icons/fi";

import { useAlert } from "react-alert";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import Spinner from "../../../ui/Spinner";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { getVendorOrder } from "../../action/orderAction";
import { Link } from "react-router-dom";

const Edit = styled(Link)`
  padding: 5px;
  border-radius: 5px;
  color: #000888;

  &:hover {
    border: 2px solid #b303ff;
  }

  &:active {
    background-color: #e098ff;
  }
`;

const Div = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20%;
  margin-bottom: 10%;
  font-size: large;
  font-weight: 700;
`;

const VendorUndeliveredOrderList = () => {
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const [rows, setRows] = useState([]);

  const { orders, error, isLoading } = useAppSelector(
    (state: any) => state.vendor
  );
  // console.log(orders, isLoading, orders?.length);
  const columns: GridColDef[] = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: "Image",
      headerName: "Image",
      width: 150,
      editable: false,

      renderCell: (params: any) => (
        <img
          alt=""
          src={params.value}
          style={{
            height: "150px",
            width: "150px",
            objectFit: "contain",
          }}
        />
      ),
    },
    {
      field: "Order",
      headerName: "Name",
      width: 150,
    },
    {
      field: "Address",
      headerName: "Address",
      width: 200,
    },

    {
      field: "Price",
      headerName: "Price",
      type: "number",
      width: 110,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "created at",
      description: "This column has a value getter and is not sortable.",
      width: 160,
    },
    {
      field: "Number",
      headerName: "Number",
      width: 150,
    },
    {
      field: "Edit",
      headerName: "Edit",
      width: 150,
      renderCell: (params: any) => (
        <Edit to={`/vendor/order/${params.value}`}>
          <FiEdit size={25} />
        </Edit>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getVendorOrder(true));

    if (error) {
      alert.error(error);
    }
  }, [dispatch, alert, error]);

  useEffect(() => {
    let as: any = [];
    orders?.forEach((order: any, i: any) => {
      as.push({
        id: i,
        Image: order?.picture,
        Order: order?.name,
        Address: order?.shippingInfo?.address,
        Price: order?.price,
        createdAt: new Date(order?.createdAt)?.toISOString()?.split("T")[0],
        Number: order?.shippingInfo?.number,
        Edit: order?._id,
      });
    });
    setRows(as);
  }, [orders]);

  if (isLoading) return <Spinner />;
  return (
    <div>
      {rows?.length ? (
        <DataGrid
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            backgroundColor: "#f2f2f2",
          }}
          rows={rows}
          columns={columns}
          rowHeight={150}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 50,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
        />
      ) : (
        <Div>No Order Found</Div>
      )}
    </div>
  );
};

export default VendorUndeliveredOrderList;
