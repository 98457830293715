import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ProductDetailState {
  product: [] | null;
  isLoading: boolean;
  error: any;
}

const initialState: ProductDetailState = {
  product: null,
  isLoading: false,
  error: "",
};

const productDetailSlice: any = createSlice({
  name: "productDetail",
  initialState,
  reducers: {
    productsDetailLoading: (state) => {
      state.isLoading = !state.isLoading;
    },
    productsDetailList: (state, action: PayloadAction<any>) => {
      state.product = action.payload;
    },
    prductDetailError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default productDetailSlice.reducer;
