import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ProductDetailPage from "./features/product/ProductDetailPage";
import AppLayout from "./ui/AppLayout";
import CartPage from "./pages/Cart";
import Wishlist from "./pages/Wishlist";
import ProductList from "./features/product/ProductList";
import Orders from "./pages/Orders";
import Shipping from "./pages/Shipping";
import ConfirmOrder from "./ui/ConfirmOrder";
import ProcessOrder from "./features/vendor/order/ProcessOrder";
import AdminDashboard from "./features/admin/AdminDashboard";
import AdminProductList from "./features/admin/AdminProductList";
import AdminOrderList from "./features/admin/order/AdminOrderList";
import UpdateProduct from "./features/vendor/UpdateProduct";
import AdminReturnedOrderList from "./features/admin/order/AdminReturnedOrderList";
import PageNotFound from "./pages/PageNotFound";
import ProtectedRoutes from "./features/routes/ProtectedRoutes";
import AdminiUndeliveredOrdersList from "./features/admin/order/AdminUndeliveredOrderList";

import VendorDashboard from "./features/vendor/VendorDashboard";
import VendorUndeliveredOrderList from "./features/vendor/order/VendorUndeliveredOrderList";
import VendorOrderList from "./features/vendor/order/OrderList";
import VendorReturnedOrderList from "./features/vendor/order/VendorReturnedOrderList";
import NewProduct from "./features/vendor/NewProduct";
import VendorProductList from "./features/vendor/VenderProductList";
import { useEffect } from "react";
import store from "./store";
import { loadUser } from "./features/action/loginAction";

function App() {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AppLayout />}>
          <Route path="/" element={<ProductList />} />
          <Route path="/search" element={<ProductList />} />
          <Route path="/search/:keyword" element={<ProductList />} />
          <Route path="/product/:id" element={<ProductDetailPage />} />
          <Route
            path="/cart"
            element={
              <ProtectedRoutes>
                <CartPage history="history" />{" "}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/wishlist"
            element={
              <ProtectedRoutes>
                {" "}
                <Wishlist />{" "}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/orders"
            element={
              <ProtectedRoutes>
                <Orders />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/vendor/order/:id"
            element={
              <ProtectedRoutes isVendorOrAdmin={true}>
                <ProcessOrder />{" "}
              </ProtectedRoutes>
            }
          />

          <Route
            path="/shipping/:productId"
            element={
              <ProtectedRoutes>
                <Shipping />
              </ProtectedRoutes>
            }
          />
          <Route path="/shipping" element={<Shipping />} />
          <Route
            path="/confirmorder"
            element={
              <ProtectedRoutes>
                <ConfirmOrder />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/confirmorder/:productId"
            element={
              <ProtectedRoutes>
                <ConfirmOrder />{" "}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/admin/dashboard"
            element={
              <ProtectedRoutes isAdmin={true}>
                <AdminDashboard />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/admin/products"
            element={
              <ProtectedRoutes isAdmin={true}>
                <AdminProductList />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/admin/orders"
            element={
              <ProtectedRoutes isAdmin={true}>
                <AdminOrderList />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/admin/undeliveredorders"
            element={
              <ProtectedRoutes isAdmin={true}>
                <AdminiUndeliveredOrdersList />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/vendor/updateproduct/:productId"
            element={
              <ProtectedRoutes isVendorOrAdmin={true}>
                <UpdateProduct />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/admin/returnorders"
            element={
              <ProtectedRoutes isAdmin={true}>
                <AdminReturnedOrderList />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoutes isVendor={true}>
                <VendorDashboard />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/vendor/undeliveredorders"
            element={
              <ProtectedRoutes isVendor={true}>
                <VendorUndeliveredOrderList />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/vendor/orders"
            element={
              <ProtectedRoutes isVendor={true}>
                <VendorOrderList />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/vendor/returnorders"
            element={
              <ProtectedRoutes isVendor={true}>
                <VendorReturnedOrderList />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/vendor/newproduct"
            element={
              <ProtectedRoutes isVendor={true}>
                {" "}
                <NewProduct />{" "}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/vendor/products"
            element={
              <ProtectedRoutes isVendor={true}>
                {" "}
                <VendorProductList />{" "}
              </ProtectedRoutes>
            }
          />

          <Route path="/login" element={<ProductList Login={true} />} />
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
