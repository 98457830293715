import { createSlice } from "@reduxjs/toolkit";
import { getAdminProducts } from "../action/productAction";

interface AdminState {
  products: [] | null;
  isLoading: boolean;
  error: [] | null;
}

const initialState: AdminState = {
  products: null,
  isLoading: false,
  error: null,
};

const adminSlice = createSlice({
  name: "adminProducts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdminProducts.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getAdminProducts.fulfilled, (state, action) => {
      state.products = action.payload.message;
      state.isLoading = false;
    });
    builder.addCase(getAdminProducts.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.isLoading = false;
    });
  },
});

export default adminSlice.reducer;
