import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_API } from "../../utils/baseApi";

export function productsList(
  keyword: string = "",
  currentPage: string | null = "1",
  price: number[],
  category: string | null,
  sort: string | null
) {
  return async function (dispatch: any) {
    try {
      dispatch({ type: "product/productsLoading" });

      let link = `/api/v1/products?keyword=${keyword}&page=${currentPage}`;
      if (price[1] > 10)
        link = `/api/v1/products?keyword=${keyword}&page=${currentPage}&price[$lte]=${price[1]}&price[$gte]=${price[0]}`;
      if (category) {
        link = `/api/v1/products?keyword=${keyword}&page=${currentPage}&category=${category}`;
      }
      if (category && price[1] > 10) {
        link = `/api/v1/products?keyword=${keyword}&page=${currentPage}&price[$lte]=${price[1]}&price[$gte]=${price[0]}&category=${category}`;
      }
      if (sort) {
        link = `/api/v1/products?keyword=${keyword}&page=${currentPage}&sort=${sort}`;
        // console.log(sort);
        if (price[1] > 10) {
          // console.log("PRICE");
          link = `/api/v1/products?keyword=${keyword}&page=${currentPage}&price[$lte]=${price[1]}&price[$gte]=${price[0]}&sort=${sort}`;
        }

        if (category) {
          // console.log("CATEGORy");
          link = `/api/v1/products?keyword=${keyword}&page=${currentPage}&category=${category}&sort=${sort}`;
        }
        if (category && price[1] > 10) {
          // console.log("PRICE AND CATEGORY ");
          link = `/api/v1/products?keyword=${keyword}&page=${currentPage}&price[$lte]=${price[1]}&price[$gte]=${price[0]}&category=${category}&sort=${sort}`;
        }
      }

      console.log(link);
      // const response = await axios.get(`${BASE_API}${link}`, {
      //   withCredentials: true,
      // });
      // const response = await axios.get(`http://13.235.95.114:8080${link}`, {
      //   withCredentials: true,
      // });
      const response = await axios.get(`${BASE_API}${link}`, {
        withCredentials: true,
      });
      const products = response?.data;
      // const product = response?.data;
      console.log(response);
      console.log(products);

      dispatch({ type: "product/productsList", payload: products });
      dispatch({ type: "product/productsLoading" });
    } catch (error) {
      dispatch({ type: "product/productsLoading" });
      console.error("Error fetching data:", error);
    }
  };
}

export function getProductDetail(productId: string) {
  return async function (dispatch: any) {
    try {
      dispatch({ type: "productDetail/productsDetailLoading" });

      const apiUrl = `${BASE_API}/api/v1/product/${productId}`;

      const response: any = await axios.get(apiUrl, { withCredentials: true });
      // console.log(response.data.product);

      dispatch({
        type: "productDetail/productsDetailList",
        payload: response?.data.product,
      });

      dispatch({ type: "productDetail/productsDetailLoading" });
    } catch (error) {
      dispatch({ type: "productDetail/productsDetailLoading" });
      dispatch({ type: "productDetail/productsDetailError", payload: error });
    }
  };
}

export const getAdminProducts = createAsyncThunk(
  "adminProducts/getAdminProducts",
  async () => {
    try {
      const { data } = await axios.get(`${BASE_API}/api/v1/admin/products`, {
        withCredentials: true,
      });
      // console.log(data);
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const getVendorProducts = createAsyncThunk(
  "vendor/getVendorProducts",
  async () => {
    try {
      const { data } = await axios.get(`${BASE_API}/api/v1/vendor/products`, {
        withCredentials: true,
      });
      // console.log(data);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const newProducts = (productData: any) => async (dispatch: any) => {
  try {
    dispatch({ type: "newProduct/ newProductLoading" });

    const { data } = await axios.post(
      `${BASE_API}/api/v1/vendor/products/new`,
      {
        headers: {},
        data: {
          product: productData,
        },
      },
      { withCredentials: true }
    );
    // console.log(data);
    dispatch({ type: "newProduct/newProduct", payload: data });
    dispatch({ type: "newProduct/ newProductLoading" });
  } catch (error: any) {
    dispatch({ type: "newProduct/ newProductLoading" });
    dispatch({ type: "newProduct/ newProductError", payload: error?.message });
  }
};

export const updateProduct = createAsyncThunk(
  "newProduct/updateProduct",
  async ({ id, productData }: { id: any; productData: any }) => {
    try {
      // console.log(id, productData);
      const { data } = await axios.patch(
        `${BASE_API}/api/v1/admin/product/${id}`,
        {
          productData,
          headers: { "Content-Type": "application/json" },
        },
        { withCredentials: true }
      );
      // console.log(data);

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const setProductStock = createAsyncThunk(
  "newProduct/setProductStock",
  async (id) => {
    try {
      const { data } = await axios.patch(
        `${BASE_API}/api/v1/vendor/product/stock/${id}`,
        {
          withCredentials: true,
        }
      );
      // console.log(data);

      return data;
    } catch (error) {
      throw error;
    }
  }
);
