import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import ProductDetail from "./ProductDetail";
import Spinner from "../../ui/Spinner";
import { getProductDetail } from "../action/productAction";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
const ProductDetailPage: React.FC = () => {
  const location = useLocation();

  // const [product, setProduct] = useState(null);
  const dispatch = useAppDispatch();
  const { product, isLoading } = useAppSelector(
    (state: any) => state.productDetail
  );

  useEffect(() => {
    // console.log(location, matches, "iuytgf");
    const productId: any = location.pathname.split("/").pop();
    dispatch(getProductDetail(productId));
    // console.log(product);
  }, [location.pathname, location, location.pathname.split("/").pop()]);
  if (isLoading) return <Spinner />;
  return (
    <div>{product ? <ProductDetail product={product} /> : <Spinner />}</div>
  );
};

export default ProductDetailPage;
