import { createSlice } from "@reduxjs/toolkit";
import { getAllOrders } from "../action/orderAction";

interface OrderState {
  orders: [] | null;
  isLoading: boolean;
  error: [] | null;
}

const initialState: OrderState = {
  orders: null,
  isLoading: false,
  error: null,
};

const allOrdersSlice = createSlice({
  name: "allOrders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllOrders.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getAllOrders.fulfilled, (state, action) => {
      state.orders = action.payload.order;
      state.isLoading = false;
    });
    builder.addCase(getAllOrders.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.isLoading = false;
    });
  },
});

export default allOrdersSlice.reducer;
