import { createSlice } from "@reduxjs/toolkit";
import {
  getOrderDetails,
  returnOrder,
  updateOrder,
} from "../action/orderAction";

interface OrderDetailState {
  orderDetail: [] | null;
  isLoading: boolean;
  error: [] | null;
  isUpdated: boolean;
}

const initialState: OrderDetailState = {
  orderDetail: null,
  isLoading: false,
  error: null,
  isUpdated: false,
};

const orderDetailSlice = createSlice({
  name: "orderDetail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrderDetails.fulfilled, (state, action) => {
      state.orderDetail = action.payload.order;
      state.isLoading = false;
    });
    builder.addCase(getOrderDetails.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getOrderDetails.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.isLoading = false;
    });
    builder.addCase(returnOrder.fulfilled, (state, action) => {
      state.orderDetail = action.payload.order;
      state.isLoading = false;
    });
    builder.addCase(returnOrder.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(returnOrder.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.isLoading = false;
    });
    builder.addCase(updateOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateOrder.fulfilled, (state, action) => {
      state.isUpdated = action.payload;
      state.isLoading = false;
    });
    builder.addCase(updateOrder.rejected, (state: any, action: any) => {
      state.isLoading = false;
      state.updateError = action.payload;
    });
  },
});

export default orderDetailSlice.reducer;
