import { createSlice } from "@reduxjs/toolkit";
import { getVendorProducts } from "../action/productAction";
import { getVendorOrder } from "../action/orderAction";

interface VendorState {
  products: [] | null;
  isLoading: boolean;
  error: [] | null;
  orders: [] | null;
}

const initialState: VendorState = {
  products: null,
  isLoading: false,
  error: null,
  orders: null,
};

const VendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVendorProducts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getVendorProducts.fulfilled, (state, action) => {
      state.products = action.payload.message;
      state.isLoading = false;
    });
    builder.addCase(getVendorProducts.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.isLoading = false;
    });
    builder.addCase(getVendorOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getVendorOrder.fulfilled, (state, action) => {
      state.orders = action.payload.order;
      state.isLoading = false;
    });
    builder.addCase(getVendorOrder.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.isLoading = false;
    });
  },
});

export default VendorSlice.reducer;
