import { configureStore } from "@reduxjs/toolkit";
import dataReducer from "./features/reducers/productSlice";
import loginReducer from "./features/reducers/loginSlice";
import cartReducer from "./features/reducers/cartSlice";
import wishlistReducer from "./features/reducers/wishlistSlice";
import orderReducer from "./features/reducers/orderSlice";
import shippingReducer from "./features/reducers/shippingSlice";
import productDetailReducer from "./features/reducers/productDetailSlice";
import orderDetailSlice from "./features/reducers/orderDetailSlice";
import adminSlice from "./features/reducers/adminSlice";
import allOrderSlice from "./features/reducers/allOrderSlice";
import newProductSlice from "./features/reducers/newProductSlice";
import vendorSlice from "./features/reducers/vendorSlice";

const store = configureStore({
  reducer: {
    products: dataReducer,
    cart: cartReducer,
    loginuser: loginReducer,
    wishList: wishlistReducer,
    orders: orderReducer,
    orderDetail: orderDetailSlice,
    shipping: shippingReducer,
    productDetail: productDetailReducer,
    adminProducts: adminSlice,
    allOrders: allOrderSlice,
    newProducts: newProductSlice,
    vendor: vendorSlice,
  },
});

export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
