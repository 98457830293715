import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ShippingState {
  shippingInfo: [] | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: ShippingState = {
  shippingInfo: null,
  isLoading: false,
  error: null,
};

const shippingSlice: any = createSlice({
  name: "shipping",
  initialState,
  reducers: {
    shippingLoading: (state) => {
      state.isLoading = !state.isLoading;
    },

    shippingInfo: (state, action: PayloadAction<any>) => {
      state.shippingInfo = action.payload.address;
    },
    shippingError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export default shippingSlice.reducer;
