import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import { useRef, useState, useEffect } from "react";
import { auth } from "./Firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { login } from "../action/loginAction";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import axios from "axios";
import RegisterForm from "./LoginForm";
import { BASE_API } from "../../utils/baseApi";

// const FormContent = styled(DialogContent)``;

const LoginModal: any = ({
  handleClickOpen,
  handleClose,
  open,
}: {
  handleClickOpen: any;
  handleClose: any;
  open: any;
}) => {
  const [WhatFor, SetWhatFor] = useState("login");
  const recaptchaRef: any = useRef();
  const { user, isAuthenticated, isLoading } = useAppSelector(
    (state: any) => state.loginuser
  );

  const [otpInfo, SetOtpInfo] = useState("");

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isAuthenticated) {
      handleClose();
    }
    if (user?.message === "register") {
      (alert as any).error("You're not a registered, Please register first");
      SetWhatFor("register");
    }
  }, [isAuthenticated, isLoading, user]);

  const setUpRecaptcha = () => {
    recaptchaRef.current.innerHTML = `<div id="phone-login"></div>`;

    (window as any).recaptchaVerifier = new RecaptchaVerifier(
      "phone-login",
      {
        size: "invisible",
        callback: (response: any) => {
          // onSignInSubmit();
        },
      },
      auth
    );
  };
  const getCodeFromUserInput = (
    otp: any,
    name: any,
    phone: any,
    method: any
  ) => {
    const confirmationResult = (window as any).confirmationResult;

    const code = otp;

    confirmationResult
      .confirm(code)
      .then(async (result: any) => {
        // console.log(result.user, phone);
        const user = result.user;
        await dispatch(login(user.accessToken, name, method));
        handleClose();
      })
      .catch((error: any) => {
        console.log(error.message);
        if (
          error.message === "Firebase: Error (auth/invalid-verification-code)."
        ) {
          SetOtpInfo("-wrong");
          (alert as any).error("Wrong Otp");
        }

        return false;
      });
  };
  const onSignInSubmit = async (e: any, phone: any, name: any, method: any) => {
    await axios.get(`${BASE_API}/api/v1/logout`, {
      withCredentials: true,
    });
    setUpRecaptcha();
    var appVerifier = (window as any).recaptchaVerifier;

    if (auth.currentUser?.phoneNumber === phone && phone && auth.currentUser) {
      // console.log("USER EXISTS IN FIREBASE WITH SAME PHONE NUMBER");
      const token = await firebaseToken();
      return await dispatch(login(token, name, method));
    }

    if (!auth.currentUser || auth.currentUser?.phoneNumber !== phone) {
      // console.log("NO USER EXISTS");
      // console.log(typeof phone, phone);
      signInWithPhoneNumber(auth, phone, appVerifier)
        .then((confirmationResult) => {
          // console.log(confirmationResult);
          (window as any).confirmationResult = confirmationResult;

          SetWhatFor("OTP");
        })
        .catch(async (error) => {
          console.log(error);
        });
    }
  };
  // const jwtToken = (e: Event) => {
  //   e.preventDefault();
  //   if (!auth.currentUser) {
  //     return console.log("no user ");
  //   }
  //   auth.currentUser
  //     .getIdToken(true)
  //     .then(function (idToken) {
  //       dispatch(login(idToken));
  //       // console.log("This IS YOUR TOKEN:", idToken);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  const firebaseToken = () => {
    if (!auth.currentUser) return;
    return auth.currentUser
      .getIdToken(true)
      .then(function (idToken) {
        return idToken;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div style={{ display: "none" }}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          {" "}
          <RegisterForm
            WhatFor={WhatFor}
            otpInfo={otpInfo}
            SetOtpInfo={SetOtpInfo}
            getCodeFromUserInput={getCodeFromUserInput}
            onSignInSubmit={onSignInSubmit}
            SetWhatFor={SetWhatFor}
          />{" "}
        </DialogContent>
      </Dialog>
      <div ref={recaptchaRef} id="recaptcha-ref">
        <div id="phone-login"></div>
      </div>
    </div>
  );
};

export default LoginModal;
