import axios from "axios";
import { BASE_API } from "../../utils/baseApi";

export const getWishList: any = () => async (dispatch: any) => {
  try {
    dispatch({
      type: "wishlist/wishlistLoading",
    });

    const { data } = await axios.get(`${BASE_API}/api/v1/wishlist`, {
      withCredentials: true,
    });
    // console.log(data.WishList);
    await dispatch({
      type: "wishlist/wishlist",
      payload: data.WishList,
    });
    // console.log("IT ALSO");
    dispatch({
      type: "wishlist/wishlistLoading",
    });
  } catch (error) {
    dispatch({
      type: "wishlist/wishlistLoading",
    });
    console.error("Error : ", error);
  }
};

export const deleteWishlist = (product: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: "wishlist/wishlistLoading",
    });

    const apiUrl = `${BASE_API}/api/v1/wishlist`;
    const { data } = await axios.delete(apiUrl, {
      headers: {},
      data: {
        productId: product,
      },
      withCredentials: true,
    });
    // console.log(data?.WishList);
    dispatch({
      type: "wishlist/wishlist",
      payload: data.WishList,
    });
    dispatch({
      type: "wishlist/wishlistLoading",
    });
  } catch (error) {
    dispatch({
      type: "wishlist/wishlistLoading",
    });
    console.error("Error : ", error);
  }
};
export const addWishlist = (product: any) => async (dispatch: any) => {
  try {
    const apiUrl = `${BASE_API}/api/v1/wishlist`;
    const { data } = await axios.put(
      apiUrl,
      {
        productId: product,
      },
      {
        withCredentials: true,
      }
    );
    console.log(data?.WishList);
  } catch (error) {
    dispatch({
      type: "wishlist/wishlistLoading",
    });
    console.error("Error : ", error);
  }
};
