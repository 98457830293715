import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_API } from "../../utils/baseApi";

export const getMyOrders: any = () => async (dispatch: any) => {
  try {
    dispatch({ type: "order/orderLoading" });

    const { data } = await axios.get(`${BASE_API}/api/v1/orders/me`, {
      withCredentials: true,
    });
    // console.log(data);
    dispatch({ type: "order/orderList", payload: data });
    dispatch({ type: "order/orderLoading" });
  } catch (error: any) {
    dispatch({ type: "order/orderLoading" });
    dispatch({
      type: "order/orderError",
      payload: error.message,
    });
  }
};

export const createOrders: any = (order: any) => async (dispatch: any) => {
  try {
    dispatch({ type: "order/orderLoading" });

    const { data } = await axios.post(
      `${BASE_API}/api/v1/order/new`,
      {
        headers: {},
        data: {
          order: order,
        },
      },
      {
        withCredentials: true,
      }
    );
    // console.log(data);
    dispatch({ type: "order/orderList", payload: data });
    dispatch({ type: "order/orderLoading" });
  } catch (error: any) {
    dispatch({ type: "order/orderLoading" });
    dispatch({
      type: "order/orderError",
      payload: error.message,
    });
  }
};

export const getReturnOrders = () => async (dispatch: any) => {
  try {
    dispatch({ type: "order/orderLoading" });

    const { data } = await axios.get(
      `${BASE_API}/api/v1/vendor/return-orders`,
      {
        withCredentials: true,
      }
    );
    // console.log(data);
    dispatch({ type: "order/orderList", payload: data });
    dispatch({ type: "order/orderLoading" });
  } catch (error: any) {
    dispatch({ type: "order/orderLoading" });
    dispatch({
      type: "order/orderError",
      payload: error.message,
    });
  }
};

export const getAllOrders = createAsyncThunk(
  "allOrders/getAllOrders",
  async (delivered: any = false) => {
    try {
      const { data } = await axios.post(
        `${BASE_API}/api/v1/admin/orders`,
        {
          headers: {},
          data: {
            nice: "NICE",
            onlyDelivered: delivered,
          },
        },
        {
          withCredentials: true,
        }
      );
      // console.log(data.order);
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const getVendorOrder = createAsyncThunk(
  "vendor/getVendorOrder",
  async (delivered: any = false) => {
    try {
      const { data } = await axios.post(
        `${BASE_API}/api/v1/vendor/orders`,
        {
          headers: {},
          data: {
            nice: "NICE",
            onlyDelivered: delivered,
          },
        },
        {
          withCredentials: true,
        }
      );
      console.log(data.order);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getAdminReturnOrder = createAsyncThunk(
  "allOrders/getAllOrders",
  async () => {
    try {
      const { data } = await axios.get(
        `${BASE_API}/api/v1/admin/return-orders`,
        {
          withCredentials: true,
        }
      );

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getOrderDetails = createAsyncThunk(
  "orderDetail/getOrderDetails",
  async (id: any) => {
    try {
      const { data } = await axios.get(`${BASE_API}/api/v1/order/${id}`, {
        withCredentials: true,
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const returnOrder: any = createAsyncThunk(
  "orderDetail/returnOrder",
  async ({ type, idww }: { type: any; idww: string }) => {
    console.log(idww);
    try {
      const { data } = await axios.patch(
        `${BASE_API}/api/v1/return-order/${idww}`,
        {
          headers: {},
          data: {
            type,
          },
        },
        {
          withCredentials: true,
        }
      );
      console.log(data);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateOrder = createAsyncThunk(
  "orderDetail/updateOrder",
  async ({ id, orderData }: { id: any; orderData: any }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.put(
        `${BASE_API}/api/v1/vendor/order/${id}`,
        {
          orderData,
          config,
        },
        {
          withCredentials: true,
        }
      );

      return data.success;
    } catch (error) {
      throw error;
    }
  }
);
